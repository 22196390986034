import React from 'react';
import Layout from 'src/layouts/Page';
import Seo from 'src/components/Seo';
import HeroSection from 'src/sections/Home/Hero';
import AppVideosSection from 'src/sections/shared/AppVideos';
import TakeOurLeadSection from 'src/sections/Home/TakeOurLead';
import BusinessSearchSection from 'src/sections/shared/BusinessSearch';
import GrowSection from 'src/sections/shared/Grow';
import TestimonialsSection from 'src/sections/shared/Testimonials';
import ApiWrapper from 'src/components/ApiWrapper';

const IndexPage: React.FC = () => (
  <ApiWrapper apiServices={['home-api', 'auth-api']} fetchLoginStatus>
    <Layout>
      <Seo />
      <HeroSection />
      <TakeOurLeadSection />
      <GrowSection />
      <BusinessSearchSection />
      <AppVideosSection />
      <TestimonialsSection isLight showBackgroundImage />
    </Layout>
  </ApiWrapper>
);

export default IndexPage;
