import { useState, useRef, useEffect } from 'react';
import uniqBy from 'lodash.uniqby';
import { SFW_BUSINESS_TYPES, CATEGORIES } from 'src/businessTypes';
import { useIsMobile } from 'src/theme';
import { navbarHeightPx } from '../Navbar/utils';

export function useBusinessSearch() {
  const isMobile = useIsMobile();
  const optionsRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLMenuElement>(null);
  const [input, setInput] = useState('');
  const [menuOpen, setMenuOpen] = useState(false);
  const [categorySelected, setCategorySelected] = useState<string | null>(null);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      switch (e.key) {
        case 'ArrowDown':
        case 'ArrowUp': {
          e.preventDefault();
          if (!optionsRef.current) return;
          const focusedOption = optionsRef.current.querySelector(':focus');
          const firstOption: HTMLElement | null = optionsRef.current.querySelector('[role="button"]:first-child');
          if (e.key === 'ArrowDown') {
            if (!focusedOption && firstOption) {
              firstOption.focus();
            } else if (focusedOption) {
              // @ts-expect-error We know that this is correct
              focusedOption.nextElementSibling?.focus();
            }
          }
          if (e.key === 'ArrowUp') {
            if (focusedOption) {
              // @ts-expect-error We know that this is correct
              focusedOption.previousElementSibling?.focus();
            }
          }
          break;
        }
        case 'Escape': {
          setMenuOpen(false);
          break;
        }
        default: break;
      }
    }

    if (menuOpen) {
      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
    return () => {};
  }, [menuOpen]);

  const [inputFocused, setInputFocused] = useState(false);
  const [menuFocused, setMenuFocused] = useState(false);
  useEffect(() => {
    // Arbitrary timeout length so that we don't instantly close the menu
    // when the focus switches from the input to the menu
    const timeout = setTimeout(() => {
      setMenuOpen(inputFocused || menuFocused);
    }, 200);
    return () => clearTimeout(timeout);
  }, [inputFocused, menuFocused]);

  useEffect(() => {
    setMenuFocused(menuOpen);
    if (!menuOpen) inputRef.current?.blur();
    if (!menuOpen) setCategorySelected(null);
    if (menuOpen && menuRef.current && inputRef.current) {
      if (menuRef.current.getBoundingClientRect().bottom > window.innerHeight) {
        window.scrollTo({
          // 20 is padding between the input and input container
          top: inputRef.current.getBoundingClientRect().top + window.scrollY - navbarHeightPx - 20,
          behavior: 'smooth',
        });
      }
    }
  }, [menuOpen]);

  useEffect(() => {
    if (categorySelected) setInput('');
  }, [categorySelected]);

  const allOptions = SFW_BUSINESS_TYPES.map((label) => ({
    label,
    isCategory: false,
  }));
  const searchableOptions = categorySelected
    ? CATEGORIES.find((c) => c.text === categorySelected)?.options.map((label) => ({
      label,
      isCategory: false,
    })) || allOptions
    : allOptions;
  const options = uniqBy(searchableOptions, 'label');
  let displayedOptions: { label: string, isCategory: boolean }[];
  if (input.length > 1) {
    displayedOptions = options
      .filter((o) => o.label.toLowerCase().includes(input.toLowerCase()));
  } else if (categorySelected) {
    displayedOptions = options;
  } else {
    displayedOptions = CATEGORIES.map((c) => ({
      label: c.text,
      isCategory: true,
    }));
  }

  function selectCategoryFromGrid(text: string) {
    setCategorySelected(text);
    setMenuOpen(true);

    // Don't focus on mobile since bringing up the keyboard is annoying
    if (!isMobile) {
      setInputFocused(true);
      inputRef.current?.focus();
    }
  }

  return {
    menuRef,
    inputRef,
    optionsRef,
    input,
    menuOpen,
    categorySelected,
    options: displayedOptions,
    selectCategoryFromGrid,
    setInputFocused,
    setMenuFocused,
    setCategorySelected,
    setInput,
    setMenuOpen,
  };
}

export default useBusinessSearch;
