import React from 'react';
import cx from 'clsx';
import useResizeObserver from 'use-resize-observer';
import { createUseStyles } from 'react-jss';
import {
  Theme, pxToRem, spacing, breakpoint,
} from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import { assetLink } from 'src/utils';
import { StaticImage } from 'gatsby-plugin-image';

const content = [
  {
    icon: assetLink('ShoppingIcon.svg'),
    title: 'Enter the digital age',
    body: 'Every small business should use a modern approach. This means having everything from an online presence on social media to offering your customers multiple payment methods.',
  },
  {
    icon: assetLink('PeopleIconAlt.svg'),
    title: 'Grow your customer base',
    body: 'Whatever your reach is, we\'ll amplify how many customers reach your site with Search Engine Optimization (SEO) and Digital Marketing tools.',
  },
  {
    icon: assetLink('TimeIcon.svg'),
    title: 'We\'re your assistant',
    body: '56% of businesses struggle to hire the right team to help bring their passion to life. Let us take that responsibility off of your shoulders.',
  },
];

interface StyleProps {
  imageWidth: number,
}

const useStyles = createUseStyles<string, StyleProps, Theme>({
  root: {
    [breakpoint.down('md')]: {
      padding: '0 !important',
    },
  },
  paddingContainer: {
    [breakpoint.down('md')]: {
      padding: `${spacing(7.5)} ${spacing(2.5)}`,
    },
  },
  // media queries do not work with parameterized styling, so we need a separate class for this
  imgContainerConstraintedHeight: ({ imageWidth }) => ({
    height: imageWidth,
  }),
  imgContainer: {
    overflow: 'hidden',
    position: 'absolute',
    borderRadius: '50%',
    width: '45%',
    maxWidth: pxToRem(1000),
    top: 0,
    right: 0,
    transform: 'translate(22%, -20%)',
    [breakpoint.down('md')]: {
      position: 'relative',
      maxHeight: pxToRem(290),
      width: '105%',
      borderRadius: 0,
      borderBottomLeftRadius: '46%',
      borderBottomRightRadius: '46%',
      top: 0,
      right: 0,
      transform: 'translate(-2.5%, -0%)',
      // TODO: Use a clipPath that works on every screen size instead of border radius
      // clipPath: 'circle(94% at 47% -31%)',
    },
  },
  content: {
    marginTop: spacing(7.5),
    [breakpoint.up('md')]: {
      width: '50%',
    },
    [breakpoint.down('md')]: {
      width: '100%',
    },
  },
});

const TakeOurLead: React.FC = () => {
  const {
    width: imageWidth = 500,
    ref: imageRef,
  } = useResizeObserver<HTMLDivElement>();
  const classes = useStyles({ imageWidth });
  return (
    <SectionContainer background="light" className={classes.root}>
      <div
        ref={imageRef}
        className={cx(classes.imgContainer, classes.imgContainerConstraintedHeight)}
      >
        <StaticImage
          src="https://assets.bizwise.com/landing-ui/sections/1.jpg"
          className="w-full h-full"
          objectFit="cover"
          objectPosition="60% 0"
          width={1200}
          alt="Male leatherworker making belt"
          placeholder="none"
        />
      </div>
      <div className={classes.paddingContainer}>
        <div style={{ maxWidth: pxToRem(1500) }}>
          <Typography variant="h2" color="textPrimary" underline>
            Take our lead
          </Typography>
          <div className={classes.content}>
            {content.map(({ icon, title, body }) => (
              <div style={{ marginBottom: spacing(7.5) }} key={icon}>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <img src={icon} alt={`${title} Icon`} style={{ width: pxToRem(85), marginTop: spacing(2.5) }} />
                  <div style={{ marginLeft: spacing(8) }}>
                    <Typography variant="h3" color="blue">
                      {title}
                    </Typography>
                    <Typography variant="p2" color="textSecondary" style={{ marginTop: pxToRem(7) }}>
                      {body}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </SectionContainer>
  );
};

export default TakeOurLead;
