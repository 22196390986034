import React from 'react';
import { createUseStyles } from 'react-jss';
import { StaticImage } from 'gatsby-plugin-image';
import { pxToRem, spacing, breakpoint } from 'src/theme';
import { assetLink } from 'src/utils';
import Typography from 'src/components/core/Typography';
import Button from 'src/components/core/Button';
import NavbarSpacer from 'src/components/Navbar/Spacer';
import SectionContainer from 'src/sections/shared/SectionContainer';
import AutoplayVideo from 'src/components/AutoplayVideo';
import VideoContainer from 'src/images/video-containers/Rectangle1.svg';

const useStyles = createUseStyles({
  root: {
    background: 'linear-gradient(105.37deg, #13266D 16.05%, #5FBCFF 97.02%)',
    position: 'relative',
    [breakpoint.down('sm')]: {
      paddingTop: 0,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      zIndex: 1,
      position: 'relative',
      width: '50%',
      [breakpoint.down('md')]: {
        width: '100%',
      },
    },
    [breakpoint.down('md')]: {
      flexDirection: 'column-reverse',
    },
  },
  shape: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 0,
    mixBlendMode: 'overlay',
    pointerEvents: 'none',
    [breakpoint.mobile()]: {
      display: 'none',
    },
  },
  imgContainer: {
    pointerEvents: 'none',
    position: 'absolute',
    width: '30%',
    maxWidth: pxToRem(220),
    borderRadius: '50%',
    // Safari fix
    '& *': {
      borderRadius: '50%',
    },
  },
  playerContainer: {
    position: 'absolute',
    zIndex: 3,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [breakpoint.up('xl')]: {
      width: '70%',
    },
    [breakpoint.between('lg', 'xl')]: {
      width: '75%',
    },
    [breakpoint.between('sm', 'lg')]: {
      width: '90%',
    },
    [breakpoint.down('sm')]: {
      width: '90%',
    },
  },
  playerBorder: {
    width: '100%',
  },
  video: {
    width: `calc(100% - ${pxToRem(17)})`,
    position: 'absolute',
  },
  button: {
    minWidth: pxToRem(180),
    [breakpoint.down('sm')]: {
      width: '100%',
    },
  },
  textContainer: {
    [breakpoint.up('md')]: {
      marginTop: '-3vh', // offset the vertical padding
    },
    [breakpoint.down('md')]: {
      // layout shifts to vertical stacking
      marginTop: spacing(5),
    },
  },
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.root}>
      <img src={assetLink('landing-ui/background-shapes/2.svg')} alt="" className={classes.shape} />
      <NavbarSpacer />
      <div className={classes.row}>
        <div className={classes.textContainer}>
          <div className="mx-0 md:mx-auto" style={{ maxWidth: pxToRem(552) }}>
            <Typography variant="h1" color="white" className="mb-5">
              You have a business. We&apos;re here to bring it online.
            </Typography>
            <Typography variant="p1" componentOverride="h2" color="white" className="mb-4 desktop:mb-7">
              With Bizwise, you get the best to start, run and grow your business.
            </Typography>
            <Typography variant="p1" color="white" className="mb-4">
              Ready to transform your business?
            </Typography>
            <Button
              variant="filled"
              color="teal"
              href="/register/"
              className={classes.button}
            >
              Start Now
            </Button>
            <Typography variant="p3" color="white" className="my-4">
              No credit card required.
            </Typography>
          </div>
        </div>
        <div>
          <div className={classes.playerContainer}>
            <AutoplayVideo
              className={classes.video}
              src={assetLink('landing-ui/videos/DashboardAnimationsCompressed.mp4')}
            />
            <VideoContainer style={{ width: '100%', height: '100%' }} />
          </div>
          <img src={assetLink('landing-ui/background-shapes/Hero1.svg')} alt="" style={{ pointerEvents: 'none' }} />
          <StaticImage
            width={220}
            height={220}
            className={classes.imgContainer}
            style={{
              bottom: 0,
              left: 0,
            }}
            src="https://assets.bizwise.com/landing-ui/sections/13.jpg"
            alt="Male carpenter"
          />
          <StaticImage
            width={220}
            height={220}
            className={classes.imgContainer}
            style={{
              bottom: 0,
              right: 0,
            }}
            src="https://assets.bizwise.com/landing-ui/sections/12.jpg"
            alt="Hairstylist blow drying hair"
          />
          <StaticImage
            width={220}
            height={220}
            className={classes.imgContainer}
            style={{
              top: 0,
              right: 0,
            }}
            src="https://assets.bizwise.com/landing-ui/sections/11.jpg"
            alt="Business woman packaging mugs"
          />
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
