import React, { useState, useEffect, useRef } from 'react';
import { useIsVisible } from 'src/hooks';

type Props = React.HTMLProps<HTMLVideoElement>;

const Video: React.FC<Props> = (props) => {
  const [videoReady, setVideoReady] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const isVisible = useIsVisible(videoRef);

  const { src } = props;
  const poster = `${src}.thumbnail.jpg`;

  // iOS workaround to force the video to load
  useEffect(() => {
    videoRef.current?.load();
  }, [videoRef]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState > 2) {
      setVideoReady(true);
    }
  }, []);

  useEffect(() => {
    if (isVisible && videoReady && videoRef.current) {
      videoRef.current.play();
    }
  }, [videoReady, isVisible]);

  return (
    <video
      controls={false}
      disableRemotePlayback
      ref={videoRef}
      muted
      loop
      playsInline
      onCanPlayThrough={() => setVideoReady(true)}
      poster={poster}
      {...props}
    />
  );
};

export default Video;
