import { assetLink } from './utils';

type Option = {
  value: string;
  category: string;
}

export const NSFW_TYPES = [
  'Abortion Clinic',
  'Abortion Service',
  'Adult DVD Store',
  'Adult Day Care Center',
  'Adult Education School',
  'Adult Education',
  'Adult Entertainment Club',
  'Adult Entertainment Store',
  'Adult Foster Care Service',
  'Alcohol Manufacturer',
  'Alcohol Retail Monopoly',
  'Alcoholic Beverage Wholesaler',
  'Birth Control Center',
  'Cabaret Club',
  'Cannabis store',
  'Erotic Massage',
  'Family Planning Center',
  'Family Planning Counselor',
  'Gambling House',
  'Gambling Instructor',
  'Gay & Lesbian Organization',
  'Gay Bar',
  'Gay Night Club',
  'Gay Sauna',
  'Girl Bar',
  'Halfway House',
  'Hookah Bar',
  'Hookah Store',
  'Lingerie Manufacturer',
  'Lingerie Store',
  'Lingerie Wholesaler',
  'Love Hotel',
  'Nudist Club',
  'Nudist Park',
  'Paternity Testing Service',
  'Sexologist',
  'Std Clinic',
  'STD Testing Service',
  'Hookah Lounge',
  'Reproductive Service',
  'Medical Cannabis Referral',
];

export const SFW_BUSINESS_TYPES = [
  'AIDS Resources',
  'ATM',
  'ATV Dealer',
  'ATV Rental Service',
  'ATV Repair Shop',
  'ATVs & Golf Carts',
  'Abbey',
  'Aboriginal Art Gallery',
  'Aboriginal and Torres Strait Islander Organisation',
  'Abrasives Supplier',
  'Abundant Life Church',
  'Accessories Store',
  'Accountant',
  'Accounting Firm',
  'Accounting School',
  'Accounting Software Company',
  'Acoustical Consultant',
  'Acrobatic Diving Pool',
  'Acrylic Store',
  'Active Life',
  'Acupuncture Clinic',
  'Acupuncture School',
  'Acupuncture',
  'Acupuncturist',
  'Acura Dealer',
  'Addiction Resources',
  'Addiction Treatment Center',
  'Administrative Attorney',
  'Admissions Training',
  'Adoption Agency',
  'Adoption Service',
  'Adventure Sports Center',
  'Adventure Sports',
  'Advertising Agency',
  'Advertising Service',
  'Aerated Drinks Supplier',
  'Aerial Photographer',
  'Aerial Sports Center',
  'Aero Dance Class',
  'Aerobics Instructor',
  'Aeroclub',
  'Aeromodel Shop',
  'Aeronautical Engineer',
  'Aerospace Company',
  'Aesthetics',
  'Afghani Restaurant',
  'African Goods Store',
  'African Methodist Episcopal Church',
  'African Restaurant',
  'After School Program',
  'Aged Care',
  'Agenzia Entrate',
  'Aggregate Supplier',
  'Agistment Service',
  'Agricultural Association',
  'Agricultural Cooperative',
  'Agricultural Engineer',
  'Agricultural High School',
  'Agricultural Machinery Manufacturer',
  'Agricultural Organization',
  'Agricultural Product Wholesaler',
  'Agricultural Production',
  'Agricultural Service Supply Agency',
  'Agricultural Service',
  'Agriculture Cooperative',
  'Agrochemicals Supplier',
  'Aikido Club',
  'Aikido School',
  'Air Compressor Repair Service',
  'Air Compressor Supplier',
  'Air Conditioning Contractor',
  'Air Conditioning Repair Service',
  'Air Conditioning Store',
  'Air Conditioning System Supplier',
  'Air Duct Cleaning Service',
  'Air Filter Supplier',
  'Air Force Base',
  'Air Taxi',
  'Airbrushing Service',
  'Airbrushing Supply Store',
  'Aircraft Maintenance Company',
  'Aircraft Manufacturer',
  'Aircraft Rental Service',
  'Aircraft Supply Store',
  'Airline Industry Service',
  'Airline Ticket Agency',
  'Airline',
  'Airplane',
  'Airport Hotel',
  'Airport Lounge',
  'Airport Shuttle Service',
  'Airport Shuttle',
  'Airport Terminal',
  'Airport',
  'Airsoft Supply Store',
  'Airstrip',
  'Alcoholism Treatment Program',
  'Allergist',
  'Allergy Doctor',
  'Alliance Church',
  'Alsace Restaurant',
  'Alternative & Holistic Health',
  'Alternative Fuel Station',
  'Alternative Healer',
  'Alternative Medicine Practitioner',
  'Alternator Supplier',
  'Aluminum Frames Supplier',
  'Aluminum Supplier',
  'Aluminum Welder',
  'Aluminum Window',
  'Amateur theater',
  'Ambulance & Rescue',
  'Ambulance Service',
  'American Football Field',
  'American Restaurant',
  'Amish Furniture Store',
  'Ammunition Supplier',
  'Amphitheater',
  'Amusement Center',
  'Amusement Machine Supplier',
  'Amusement Park Ride',
  'Amusement Park',
  'Amusement Ride Supplier',
  'Amusement',
  'An Hui Restaurant',
  'Anago Restaurant',
  'Andalusian Restaurant',
  'Anesthesiologist',
  'Angler Fish Restaurant',
  'Anglican Church',
  'Animal Control Service',
  'Animal Feed Store',
  'Animal Hospital',
  'Animal Park',
  'Animal Protection Organization',
  'Animal Rescue Service',
  'Animal Shelter',
  'Animation Studio',
  'Anime Club',
  'Anodizer',
  'Antenna Service',
  'Antique Furniture Restoration Service',
  'Antique Furniture Store',
  'Antique Store',
  'Antiques & Vintage',
  'Apartment & Condo Building',
  'Apartment Building',
  'Apartment Complex',
  'Apartment Rental Agency',
  'Apostolic Church',
  'App Development',
  'Appliance Parts Supplier',
  'Appliance Rental Service',
  'Appliance Repair Service',
  'Appliance Store',
  'Appliances Customer Service',
  'Appraiser',
  'Apprenticeship Center',
  'Aquaculture Farm',
  'Aquarium Shop',
  'Aquarium',
  'Aquatic Centre',
  'Arboretum',
  'Arborist and Tree Surgeon',
  'Arcade',
  'Archaeological Museum',
  'Archaeological Service',
  'Archery Club',
  'Archery Event',
  'Archery Hall',
  'Archery Range',
  'Archery Store',
  'Archery',
  'Architect',
  'Architects Association',
  'Architectural Designer',
  'Architectural Salvage Store',
  'Architectural and Engineering Model Maker',
  'Architecture Firm',
  'Architecture School',
  'Archive',
  'Arena',
  'Arepa Restaurant',
  'Argentinian Restaurant',
  'Armed Forces Association',
  'Armed Forces',
  'Armenian Church',
  'Armenian Restaurant',
  'Army & Navy Surplus Shop',
  'Army Barracks',
  'Army Facility',
  'Army Museum',
  'Aromatherapy Class',
  'Aromatherapy Service',
  'Aromatherapy Supply Store',
  'Aromatherapy',
  'Art Cafe',
  'Art Center',
  'Art Dealer',
  'Art Gallery',
  'Art Handcraft',
  'Art Museum',
  'Art Restoration Service',
  'Art Restoration',
  'Art School',
  'Art Studio',
  'Art Supply Store',
  'Artificial Plant Supplier',
  'Artist',
  'Artistic Painting School',
  'Artistic Service',
  'Arts & Crafts Supply Store',
  'Arts Organization',
  'Asbestos Testing Service',
  'Ashram',
  'Asian Fusion Restaurant',
  'Asian Grocery Store',
  'Asian Household Goods Store',
  'Asian Massage Therapist',
  'Asian Restaurant',
  'Asphalt Contractor',
  'Asphalt Mixing Plant',
  'Assemblies of God Church',
  'Assembly Room',
  'Assembly of God',
  'Assistante Maternelle',
  'Assisted Living Facility',
  'Association or Organization',
  'Astrologer',
  'Asturian Restaurant',
  'Athletic Club',
  'Athletic Education',
  'Athletic Field',
  'Athletic Park',
  'Athletic Track',
  'Attorney Referral Service',
  'Auction House',
  'Audi Dealer',
  'Audio Visual Consultant',
  'Audio Visual Equipment Rental Service',
  'Audio Visual Equipment Repair Service',
  'Audio Visual Equipment Supplier',
  'Audiologist',
  'Audiovisual Equipment',
  'Auditor',
  'Auditorium',
  'Australian Goods Store',
  'Australian Restaurant',
  'Austrian Restaurant',
  'Authentic Japanese Restaurant',
  'Auto Accessories Wholesaler',
  'Auto Air Conditioning Service',
  'Auto Auction',
  'Auto Body Parts Supplier',
  'Auto Body Shop',
  'Auto Broker',
  'Auto Chemistry Shop',
  'Auto Dent Removal Service',
  'Auto Electrical Service',
  'Auto Glass Shop',
  'Auto Insurance Agency',
  'Auto Machine Shop',
  'Auto Market',
  'Auto Parts Manufacturer',
  'Auto Parts Market',
  'Auto Parts Store',
  'Auto Radiator Repair Service',
  'Auto Repair Shop',
  'Auto Restoration Service',
  'Auto Spring Shop',
  'Auto Sunroof Shop',
  'Auto Tag Agency',
  'Auto Tune Up Service',
  'Auto Upholsterer',
  'Auto Wrecker',
  'Automation Company',
  'Automation Service',
  'Automobile Leasing',
  'Automobile Storage Facility',
  'Automotive Body Shop',
  'Automotive Customizing',
  'Automotive Garage',
  'Automotive Glass Service',
  'Automotive Manufacturing',
  'Automotive Mechanic',
  'Automotive Parts & Accessories',
  'Automotive Repair',
  'Automotive Storage',
  'Automotive Wholesaler',
  'Automotive',
  'Aviation Consultant',
  'Aviation Fuel',
  'Aviation School',
  'Aviation Training Institute',
  'Awning Supplier',
  'Awnings & Canopies',
  'BBQ Area',
  'BBQ Restaurant',
  'BMW Dealer',
  'BMW Motorcycle Dealer',
  'BMX Club',
  'BMX Park',
  'BMX Track',
  'BPO Company',
  'BPO Placement Agency',
  'Baby Clothing Store',
  'Baby Store',
  'Baby Swimming School',
  'Babysitter',
  'Badminton Club',
  'Badminton Complex',
  'Badminton Court',
  'Bag Shop',
  'Bagel Shop',
  'Bahá\'í House of Worship',
  'Bail Bonds Service',
  'Bail Bonds',
  'Bailiff',
  'Bait Shop',
  'Bakery Equipment',
  'Bakery',
  'Baking Supply Store',
  'Ballet School',
  'Ballet Theater',
  'Balloon Artist',
  'Balloon Ride Tour Agency',
  'Balloon Store',
  'Balloonport',
  'Ballroom Dance Instructor',
  'Ballroom',
  'Band',
  'Bangladeshi Restaurant',
  'Bank Equipment & Service',
  'Bank',
  'Bankruptcy Attorney',
  'Bankruptcy Lawyer',
  'Bankruptcy Service',
  'Banner Store',
  'Banquet Hall',
  'Baptist Church',
  'Bar & Grill',
  'Bar PMU',
  'Bar Restaurant Furniture Store',
  'Bar Stool Supplier',
  'Bar Tabac',
  'Bar',
  'Barbecue Restaurant',
  'Barbecue area',
  'Barber School',
  'Barber Shop',
  'Barber Supply Store',
  'Bark Supplier',
  'Barrel Supplier',
  'Barrister',
  'Bartending School',
  'Bartending Service',
  'Baseball Club',
  'Baseball Field',
  'Baseball Goods Store',
  'Baseball',
  'Basilica',
  'Basket Supplier',
  'Basketball Club',
  'Basketball Court Contractor',
  'Basketball Court',
  'Basque Restaurant',
  'Bathroom Remodeler',
  'Bathroom Supply Store',
  'Battery Manufacturer',
  'Battery Store',
  'Battery Wholesaler',
  'Batting Cage Center',
  'Batting Cage',
  'Bazar',
  'Beach Bar',
  'Beach Cleaning Service',
  'Beach Clothing Store',
  'Beach Entertainment Shop',
  'Beach Pavillion',
  'Beach Resort',
  'Beach Volleyball Club',
  'Beach Volleyball Court',
  'Beach',
  'Bead Store',
  'Bead Wholesaler',
  'Bearing Supplier',
  'Beauty Product Supplier',
  'Beauty Products Vending Machine',
  'Beauty Products Wholesaler',
  'Beauty Salon',
  'Beauty School',
  'Beauty Supply Store',
  'Bed & Breakfast',
  'Bed Shop',
  'Bed and Breakfast',
  'Bedding Store',
  'Bedroom Furniture Store',
  'Beer Bar',
  'Beer Distributor',
  'Beer Garden',
  'Beer Hall',
  'Beer Store',
  'Belgian Restaurant',
  'Belt Shop',
  'Berry Restaurant',
  'Betting Agency',
  'Beverage Distributor',
  'Bible Church',
  'Bicycle Club',
  'Bicycle Rack',
  'Bicycle Rental Service',
  'Bicycle Repair Shop',
  'Bicycle Store',
  'Bicycle Wholesale',
  'Big Box Retailer',
  'Bike Rental & Bike Share',
  'Bike Sharing Station',
  'Bike Shop',
  'Bikram Yoga Studio',
  'Bilingual School',
  'Billiards Supply Store',
  'Bingo Hall',
  'Biochemical Supplier',
  'Biochemistry Lab',
  'Biofeedback Therapist',
  'Biotechnology Company',
  'Biotechnology Engineer',
  'Biotechnology',
  'Bird Control Service',
  'Bird Shop',
  'Bird Watching Area',
  'Birth Center',
  'Birth Certificate Service',
  'Bistro',
  'Blacksmith',
  'Blast Cleaning Service',
  'Blind School',
  'Blinds & Curtains',
  'Blinds Shop',
  'Blood Bank',
  'Blood Donation Center',
  'Blood Testing Service',
  'Blueprint Service',
  'Blues Club',
  'Board Game Club',
  'Board of Education',
  'Board of Trade',
  'Boarding House',
  'Boarding School',
  'Boat Accessories Supplier',
  'Boat Builders',
  'Boat Club',
  'Boat Cover Supplier',
  'Boat Dealer',
  'Boat Dealership',
  'Boat Ramp',
  'Boat Rental Service',
  'Boat Rental',
  'Boat Repair Shop',
  'Boat Service',
  'Boat Storage Facility',
  'Boat Tour Agency',
  'Boat Trailer Dealer',
  'Boatel',
  'Boating Instructor',
  'Boating',
  'Bocce Ball Court',
  'Bodega',
  'Body Piercing Shop',
  'Body Shaping Class',
  'Boiler Manufacturer',
  'Boiler Supplier',
  'Bonesetting House',
  'Bonsai Plant Supplier',
  'Book & Magazine Distribution',
  'Book Publisher',
  'Book Store',
  'Bookbinder',
  'Bookkeeping Service',
  'Bookmaker',
  'Books Wholesaler',
  'Boot Camp',
  'Boot Repair Shop',
  'Boot Store',
  'Border Crossing Station',
  'Border Guard',
  'Botanical Garden',
  'Bottle & Can Redemption Center',
  'Bottled Water Supplier',
  'Bouncy Castle Hire',
  'Boutique Hotel',
  'Boutique',
  'Bowling Alley',
  'Bowling Club',
  'Bowling Supply Shop',
  'Box Lunch Supplier',
  'Boxing Club',
  'Boxing Gym',
  'Boxing Ring',
  'Boys\' High School',
  'Brake Shop',
  'Brasserie',
  'Brazilian Restaurant',
  'Breakfast & Brunch Restaurant',
  'Breakfast Restaurant',
  'Brewery',
  'Brewing Supply Store',
  'Brewpub',
  'Brick Manufacturer',
  'Bricklayer',
  'Bridal Shop',
  'Bridge Club',
  'Bridge',
  'British Restaurant',
  'Broadcasting & Media Production',
  'Brokers & Franchising',
  'Brunch Restaurant',
  'Bubble Tea Shop',
  'Bubble Tea',
  'Buddhist Supplies Store',
  'Buddhist Temple',
  'Budget Hotel',
  'Budget Japanese Inn',
  'Buffet Restaurant',
  'Buick Dealer',
  'Building Consultant',
  'Building Design Company',
  'Building Equipment Hire Service',
  'Building Firm',
  'Building Inspector',
  'Building Materials Market',
  'Building Materials Store',
  'Building Materials Supplier',
  'Building Restoration Service',
  'Building Society',
  'Bulgarian Restaurant',
  'Bullring',
  'Bungee Jumping Center',
  'Burger Restaurant',
  'Burglar Alarm Store',
  'Burial & Cremation Service',
  'Burmese Restaurant',
  'Burrito Place',
  'Burrito Restaurant',
  'Bus Charter',
  'Bus Company',
  'Bus Station',
  'Bus Ticket Agency',
  'Bus Tour Agency',
  'Bus and Coach Company',
  'Business Administration Service',
  'Business Broker',
  'Business Center',
  'Business Consultant',
  'Business Development Service',
  'Business Hotel',
  'Business Management Consultant',
  'Business Networking Company',
  'Business Park',
  'Business School',
  'Business Service:',
  'Business Supply Service',
  'Business to Business Service',
  'Butane Gas Supplier',
  'Butcher Shop Deli',
  'Butcher Shop',
  'Butsudan Store',
  'CBSE School',
  'CD Store',
  'CNG Fittment Center',
  'CREATIVE AND LIFESTYLE SERVICES:',
  'Cabin Rental Agency',
  'Cabin',
  'Cabinet Maker',
  'Cabinet Store',
  'Cabinets & Countertops',
  'Cable & Satellite Service',
  'Cable Company',
  'Cadillac Dealer',
  'Cafe',
  'Cafeteria',
  'Cajun & Creole Restaurant',
  'Cajun Restaurant',
  'Cake Decorating Equipment Shop',
  'Cake Shop',
  'Californian Restaurant',
  'Call Center',
  'Call Shop',
  'Calligraphy Lesson',
  'Calvary Chapel Church',
  'Cambodian Restaurant',
  'Camera Repair Shop',
  'Camera Store',
  'Camp',
  'Camper Shell Supplier',
  'Campground',
  'Camping Farm',
  'Camping Store',
  'Campus Building',
  'Canadian Restaurant',
  'Cancer Treatment Center',
  'Candle Store',
  'Candy Store',
  'Cane Furniture Store',
  'Cannery',
  'Canoe & Kayak Rental Service',
  'Canoe & Kayak Store',
  'Canoe & Kayak Tour Agency',
  'Canoe and Kayak Club',
  'Canoeing Area',
  'Cantabrian Restaurant',
  'Cantonese Restaurant',
  'Cape Verdean Restaurant',
  'Capital',
  'Capoeira School',
  'Capsule Hotel',
  'Car Accessories Store',
  'Car Alarm Supplier',
  'Car Battery Store',
  'Car Dealer',
  'Car Dealership',
  'Car Detailing Service',
  'Car Factory',
  'Car Finance and Loan Company',
  'Car Inspection Station',
  'Car Leasing Service',
  'Car Manufacturer',
  'Car Racing Track',
  'Car Rental Agency',
  'Car Rental',
  'Car Repair and Maintenance',
  'Car Security System Installer',
  'Car Service',
  'Car Sharing Location',
  'Car Stereo Store',
  'Car Wash & Detailing',
  'Car Wash',
  'Carabinieri Police',
  'Cardiologist',
  'Career Guidance Service',
  'Cargo & Freight Company',
  'Caribbean Restaurant',
  'Carnival Club',
  'Carnival Supplies',
  'Carpenter',
  'Carpet & Flooring Store',
  'Carpet Cleaner',
  'Carpet Cleaning Service',
  'Carpet Installer',
  'Carpet Manufacturer',
  'Carpet Store',
  'Carpet Wholesaler',
  'Carpool',
  'Carport and Pergola Builder',
  'Carriage Ride Service',
  'Carvery',
  'Cash Advance Service',
  'Cash and Carry Wholesaler',
  'Casino',
  'Casket Service',
  'Castilian Restaurant',
  'Castle Hotel',
  'Castle',
  'Cat Hostel',
  'Catalonian Restaurant',
  'Caterer',
  'Catering Food and Drink Supplier',
  'Cathedral',
  'Catholic Cathedral',
  'Catholic Church',
  'Catholic School',
  'Cattery',
  'Cattle Farm',
  'Cattle Market',
  'Ceiling Supplier',
  'Cell Phone Accessory Store',
  'Cell Phone Store',
  'Cement Manufacturer',
  'Cement Supplier',
  'Cemetery',
  'Central American Restaurant',
  'Central Authority',
  'Central Bank',
  'Ceramic Manufacturer',
  'Ceramics Wholesaler',
  'Certification Agency',
  'Certified Public Accountant',
  'Chalet',
  'Chamber of Agriculture',
  'Chamber of Commerce',
  'Chamber of Handicrafts',
  'Champagne Bar',
  'Champon Noodle Restaurant',
  'Chanko Restaurant',
  'Chapel',
  'Charcuterie',
  'Charismatic Church',
  'Charity Organization',
  'Charity',
  'Charter Buses',
  'Charter School',
  'Chartered Accountant',
  'Chartered Surveyor',
  'Check Cashing Service',
  'Cheerleading',
  'Cheese Manufacturer',
  'Cheese Shop',
  'Cheesesteak Restaurant',
  'Chemical Engineer',
  'Chemical Exporter',
  'Chemical Manufacturer',
  'Chemical Plant',
  'Chemical Wholesaler',
  'Chemicals',
  'Chemistry Faculty',
  'Chemistry Lab',
  'Chesapeake Restaurant',
  'Chess Club',
  'Chess Instructor',
  'Chess and Card Club',
  'Chevrolet Dealer',
  'Chicken Hatchery',
  'Chicken Restaurant',
  'Chicken Shop',
  'Chicken Wings Restaurant',
  'Chicken Wings',
  'Child Care Agency',
  'Child Care',
  'Child Health Care Centre',
  'Child Protective Service',
  'Child Psychologist',
  'Childbirth Class',
  'Childminder',
  'Children Hall',
  'Children Policlinic',
  'Childrens Book Store',
  'Childrens Cafe',
  'Childrens Club',
  'Childrens Farm',
  'Childrens Home',
  'Childrens Library',
  'Childrens Party Buffet',
  'Childrens Store',
  'Childrens Theater',
  'Children\'s Amusement Center',
  'Children\'s Clothing Store',
  'Children\'s Furniture Store',
  'Children\'s Hospital',
  'Children\'s Museum',
  'Children\'s Party Service',
  'Chilean Restaurant',
  'Chimney Services',
  'Chimney Sweep',
  'Chinaware Store',
  'Chinese Language Instructor',
  'Chinese Language School',
  'Chinese Medical Hospital',
  'Chinese Medicine Clinic',
  'Chinese Medicine Store',
  'Chinese Noodle Restaurant',
  'Chinese Pastry',
  'Chinese Restaurant',
  'Chinese Supermarket',
  'Chinese Takeaway',
  'Chinese Tea House',
  'Chiropractor',
  'Chocolate Artisan',
  'Chocolate Cafe',
  'Chocolate Factory',
  'Chocolate Shop',
  'Choir',
  'Chophouse Restaurant',
  'Christian Book Store',
  'Christian Church',
  'Christian College',
  'Christian Science Church',
  'Christmas Market',
  'Christmas Store',
  'Christmas Tree Farm',
  'Chrysler Dealer',
  'Church Supply Store',
  'Church of Christ',
  'Church of God',
  'Church of Jesus Christ of Latter-day Saints',
  'Church of the Nazarene',
  'Church',
  'Churreria',
  'Cider Bar',
  'Cider Mill',
  'Cigar Shop',
  'Cinema Equipment Supplier',
  'Circular Distribution Service',
  'Circus',
  'Citizen Information Bureau',
  'Citizens Advice Bureau',
  'City Administration',
  'City Clerk\'s Office',
  'City Courthouse',
  'City Department of Environment',
  'City Department of Public Safety',
  'City Department of Transportation',
  'City District Office',
  'City Employment Department',
  'City Government Office',
  'City Hall',
  'City Hospital',
  'City Park',
  'City Tax Office',
  'City or Town Hall',
  'City pillar shrine',
  'City',
  'Civic Center',
  'Civic Structure',
  'Civil Defence',
  'Civil Engineer',
  'Civil Engineering Company',
  'Civil Examinations Academy',
  'Civil Law Attorney',
  'Civil Police',
  'Civil Registry',
  'Civil Rights Law',
  'Class',
  'Classes',
  'Classified Ads Newspaper Publisher',
  'Cleaners',
  'Cleaning Products Supplier',
  'Cleaning Service',
  'Clergy',
  'Clergyman',
  'Clinic',
  'Clock Repair Service',
  'Clock Watch Maker',
  'Closed Circuit Television',
  'Clothes Market',
  'Clothes and Fabric Manufacturer',
  'Clothes and Fabric Wholesaler',
  'Clothing Alteration Service',
  'Clothing Store',
  'Clothing Supplier',
  'Clothing Supply & Distribution',
  'Clothing Wholesale Market Place',
  'Clothing Wholesaler',
  'Club',
  'Clubhouse',
  'Co-ed School',
  'Coaching Center',
  'Coal Exporter',
  'Coal Supplier',
  'Coalfield',
  'Coast Guard Station',
  'Coat Wholesaler',
  'Cocktail Bar',
  'Coffee Machine Supplier',
  'Coffee Roasters',
  'Coffee Shop',
  'Coffee Store',
  'Coffee Vending Machine',
  'Coffee Wholesaler',
  'Coffin Supplier',
  'Coin Dealer',
  'Coin Operated Laundry Equipment Supplier',
  'Coin Operated Locker',
  'Cold Cut Store',
  'Cold Noodle Restaurant',
  'Cold Storage Facility',
  'Collectibles Store',
  'Collection Agency',
  'College & University',
  'College of Agriculture',
  'College',
  'Colombian Restaurant',
  'Comedy Club',
  'Comfort Food Restaurant',
  'Comic Book Store',
  'Comic Cafe',
  'Commercial & Industrial Equipment',
  'Commercial Agent',
  'Commercial Automotive',
  'Commercial Bank',
  'Commercial Cleaning Service',
  'Commercial Photographer',
  'Commercial Printer',
  'Commercial Real Estate Agency',
  'Commercial Real Estate Agent',
  'Commercial Real Estate Broker',
  'Commercial Real Estate Inspector',
  'Commercial Real Estate',
  'Commercial Refrigeration',
  'Commercial Refrigerator Supplier',
  'Commissioner for Oaths',
  'Communications Central',
  'Community Center',
  'Community College',
  'Community Garden',
  'Community Health Centre',
  'Community Organization',
  'Community School',
  'Community, Organization and Education:',
  'Company Registry',
  'Computer Accessories Store',
  'Computer Club',
  'Computer Consultant',
  'Computer Desk Store',
  'Computer Hardware Manufacturer',
  'Computer Networking Center',
  'Computer Repair Service',
  'Computer Security Service',
  'Computer Service',
  'Computer Software Store',
  'Computer Store',
  'Computer Support and Services',
  'Computer Training School',
  'Computer Training',
  'Computer Wholesaler',
  'Computers & Electronics',
  'Concert Hall',
  'Concert Venue',
  'Concrete Contractor',
  'Concrete Factory',
  'Concrete Metal Framework Supplier',
  'Concrete Product Supplier',
  'Condiments Supplier',
  'Condominium Complex',
  'Condominium Rental Agency',
  'Confectionery Wholesaler',
  'Confectionery',
  'Conference Center',
  'Conference Hotel',
  'Confucian Temple',
  'Congregation',
  'Congregational Church',
  'Conservation Department',
  'Conservative Club',
  'Conservative Synagogue',
  'Conservatory Construction Contractor',
  'Conservatory Supply & Installation',
  'Conservatory of Music',
  'Consignment Shop',
  'Construction Company',
  'Construction Equipment Supplier',
  'Construction Machine Dealer',
  'Construction Machine Rental Service',
  'Construction Material Wholesaler',
  'Construction and Maintenance Office',
  'Consulate & Embassy',
  'Consultant',
  'Consumer Advice Center',
  'Consumer Startup',
  'Contact Lenses Supplier',
  'Container Service',
  'Container Supplier',
  'Container Terminal',
  'Containers Supplier',
  'Contemporary Louisiana Restaurant',
  'Content Services',
  'Continental Restaurant',
  'Contract Law',
  'Contractor',
  'Convenience Store',
  'Convenience Stores Organization',
  'Convent & Monastery',
  'Convent',
  'Convention Center',
  'Convention Information Bureau',
  'Conveyancer',
  'Conveyor Belt Sushi Restaurant',
  'Cookie Shop',
  'Cooking Class',
  'Cooking Lesson',
  'Cooking School',
  'Cooling Plant',
  'Copier Repair Service',
  'Copper Supplier',
  'Coppersmith',
  'Copy Shop',
  'Copying & Printing',
  'Copying Supply Store',
  'Copywriting Service',
  'Corporate Campus',
  'Corporate Entertainment Service',
  'Corporate Gift Supplier',
  'Corporate Lawyer',
  'Corporate Office',
  'Correctional Services Department',
  'Cosmetic Dentist',
  'Cosmetic Products Manufacturer',
  'Cosmetic Surgeon',
  'Cosmetics & Beauty Supply',
  'Cosmetics Industry',
  'Cosmetics Store',
  'Cosmetics Wholesaler',
  'Cosmetics and Parfumes Supplier',
  'Cosplay Cafe',
  'Costa Rican Restaurant',
  'Costume Jewelry Shop',
  'Costume Rental Service',
  'Costume Shop',
  'Costume Store',
  'Cottage Rental',
  'Cottage Village',
  'Cottage',
  'Cotton Exporter',
  'Cotton Mill',
  'Cotton Supplier',
  'Council',
  'Counseling & Mental Health',
  'Counselor',
  'Countertop Store',
  'Country Club',
  'Country Food Restaurant',
  'Country House',
  'Country Park',
  'County Government Office',
  'County',
  'Courier Service',
  'Cours de Capoeira',
  'Cours de Surf',
  'Court Executive Officer',
  'Court Reporter',
  'Courthouse',
  'Couscous Restaurant',
  'Couture Store',
  'Coworking Space',
  'Crab House',
  'Craft Centre',
  'Craft Store',
  'Cramming School',
  'Crane Dealer',
  'Crane Rental Agency',
  'Crane Service',
  'Craniosacral Therapy',
  'Creche',
  'Credit Counseling Service',
  'Credit Counseling',
  'Credit Reporting Agency',
  'Credit Union',
  'Cremation Service',
  'Creole Restaurant',
  'Cricket Club',
  'Cricket Ground',
  'Cricket Shop',
  'Crime Victim Service',
  'Criminal Justice Attorney',
  'Criminal Lawyer',
  'Crisis Prevention Center',
  'Croatian Restaurant',
  'Crop Grower',
  'Croquet Club',
  'Cruise Agency',
  'Cruise Excursions',
  'Cruise Line Company',
  'Cruise Terminal',
  'Cruise',
  'Crushed Stone Supplier',
  'Crêperie',
  'Cuban Restaurant',
  'Culinary School',
  'Cultural Association',
  'Cultural Center',
  'Cultural Gifts Store',
  'Cupcake Shop',
  'Cured Ham Bar',
  'Cured Ham Store',
  'Cured Ham Warehouse',
  'Curling Club',
  'Curling Hall',
  'Currency Exchange Service',
  'Currency Exchange',
  'Curtain Store',
  'Curtain Supplier and Maker',
  'Curtain and Upholstery Cleaning Service',
  'Custom Confiscated Goods Store',
  'Custom Home Builder',
  'Custom Label Printer',
  'Custom T-shirt Store',
  'Custom Tailor',
  'Customs Broker',
  'Customs Consultant',
  'Customs Department',
  'Customs Office',
  'Customs Warehouse',
  'Cutlery Store',
  'Cycling Park',
  'Czech Restaurant',
  'DJ Service',
  'DJ Supply Store',
  'DJ',
  'DMV',
  'DUI Law',
  'DVD & Video Store',
  'DVD Store',
  'Dairy Farm Equipment Supplier',
  'Dairy Farm',
  'Dairy Store',
  'Dairy Supplier',
  'Dairy',
  'Damage Restoration Service',
  'Dan Dan Noodle Restaurant',
  'Dance Club',
  'Dance Company',
  'Dance Conservatory',
  'Dance Hall',
  'Dance Instruction',
  'Dance Pavillion',
  'Dance Restaurant',
  'Dance School',
  'Dance Store',
  'Danish Restaurant',
  'Dart Bar',
  'Dart Supply Store',
  'Data Entry Service',
  'Data Recovery Service',
  'Database Management Company',
  'Dating Service',
  'Day Care Center',
  'Day Care',
  'Day Spa',
  'Deaf Church',
  'Deaf School',
  'Deaf Service',
  'Debris Removal Service',
  'Debt Collecting',
  'Debt Collection Agency',
  'Decal Supplier',
  'Deck & Patio',
  'Deck Builder',
  'Deli',
  'Delivery Chinese Restaurant',
  'Delivery Service',
  'Demolition Contractor',
  'Dental Clinic',
  'Dental Equipment',
  'Dental Hygienist',
  'Dental Implants Periodontist',
  'Dental Insurance Agency',
  'Dental Laboratory',
  'Dental Radiology',
  'Dental School',
  'Dental Supply Store',
  'Dentist',
  'Denture Care Center',
  'Department Store',
  'Department for Regional Development',
  'Department of Education',
  'Department of Finance',
  'Department of Housing',
  'Department of Motor Vehicles',
  'Department of Public Safety',
  'Department of Social Services',
  'Department of Transportation',
  'Dept of City Treasure',
  'Dept of State Treasure',
  'Dermatologist',
  'Desalination Plant',
  'Design Agency',
  'Design Engineer',
  'Design Institute',
  'Desktop Publishing Service',
  'Dessert Place',
  'Dessert Restaurant',
  'Dessert Shop',
  'Detective',
  'Detention Center',
  'Diabetes Center',
  'Diabetes Equipment Supplier',
  'Diabetologist',
  'Diagnostic Center',
  'Dialysis Center',
  'Diamond Buyer',
  'Diamond Dealer',
  'Diaper Service',
  'Diesel Engine Dealer',
  'Diesel Engine Repair Service',
  'Diesel Fuel Supplier',
  'Digital Design',
  'Digital Printer',
  'Digital Printing Service',
  'Dim Sum Restaurant',
  'Diner',
  'Dinner Theater',
  'Direct Mail Advertising',
  'Direct Mail Service',
  'Dirt Supplier',
  'Disability Equipment Supplier',
  'Disability Law',
  'Disability Service',
  'Disability Services & Support Organisation',
  'Disabled Persons Service',
  'Disabled Sports Center',
  'Disaster Relief',
  'Disc Golf Course',
  'Disciples of Christ Church',
  'Disco Club',
  'Discount Store',
  'Discount Supermarket',
  'Display Home Centre',
  'Display Stand Manufacturer',
  'Disposable Tableware Supplier',
  'Distance Learning Center',
  'Distillery',
  'Distribution Service',
  'District Attorney',
  'District Council',
  'District Government Office',
  'District Justice',
  'District Office',
  'Dive Bar',
  'Dive Club',
  'Dive Shop',
  'Diving Center',
  'Diving Contractor',
  'Divorce & Family Lawyer',
  'Divorce Lawyer',
  'Divorce Service',
  'Do-it-Yourself Shop',
  'Dock Builder',
  'Doctor',
  'Document Service',
  'Dodge Dealer',
  'Dog Breeder',
  'Dog Cafe',
  'Dog Day Care Center',
  'Dog Hostel',
  'Dog Park',
  'Dog Sitter',
  'Dog Trainer',
  'Dog Training',
  'Dog Walker',
  'Dogsled Ride Service',
  'Dojo Restaurant',
  'Doll Restoration Service',
  'Doll Store',
  'Dollar Store',
  'Domestic Abuse Treatment Center',
  'Domestic Airport',
  'Dominican Restaurant',
  'Donations Center',
  'Doner Restaurant',
  'Donut Shop',
  'Donuts Shop',
  'Door Manufacturer',
  'Door Shop',
  'Door Supplier',
  'Door Warehouse',
  'Dorm',
  'Double Glazing Installer',
  'Doula',
  'Down Home Cooking Restaurant',
  'Drafting Equipment Supplier',
  'Drafting Service',
  'Drainage Service',
  'Drama School',
  'Drama Theater',
  'Drawing Lessons',
  'Dress Store',
  'Dress and Tuxedo Rental Service',
  'Dressmaker',
  'Dried Flower Shop',
  'Dried Seafood Store',
  'Drilling Contractor',
  'Drilling Equipment Supplier',
  'Drinking Water Distribution',
  'Drinking Water Fountain',
  'Drive In Restaurant',
  'Drive-in Movie Theater',
  'Driver and Vehicle Licensing Agency',
  'Drivers License Training School',
  'Driver\'s License Office',
  'Driveshaft Shop',
  'Driving Range',
  'Driving School',
  'Driving Test Centre',
  'Drug & Alcohol Rehab',
  'Drug Addiction Treatment Center',
  'Drug Store',
  'Drug Testing Service',
  'Drum School',
  'Drum Store',
  'Dry Cleaner',
  'Dry Fruit Store',
  'Dry Ice Supplier',
  'Dry Wall Contractor',
  'Dry Wall Supply Store',
  'Ducati Dealer',
  'Dude Ranch',
  'Dump Truck Dealer',
  'Dump Truck Service',
  'Dumpling Restaurant',
  'Dutch Restaurant',
  'Duty Free Store',
  'Dye Store',
  'Dyeworks',
  'Dynamometer Supplier',
  'E Commerce Agency',
  'E-commerce Service',
  'Ear Nose & Throat',
  'Ear Piercing Service',
  'Earth Works Company',
  'East African Restaurant',
  'Eastern European Restaurant',
  'Eastern Orthodox Church',
  'Eating Disorder Treatment Center',
  'Eclectic Restaurant',
  'Eco Tours',
  'Ecological Park',
  'Ecologists Association',
  'Economic Consultant',
  'Economic Development Agency',
  'Ecuadorian Restaurant',
  'Education Center',
  'Education',
  'Educational Camp',
  'Educational Consultant',
  'Educational Institution',
  'Educational Organization',
  'Educational Research',
  'Educational Service',
  'Educational Supplies',
  'Educational Supply Store',
  'Educational Testing Service',
  'Eftpos Equipment Supplier',
  'Egg Supplier',
  'Egyptian Restaurant',
  'Elder Law Attorney',
  'Electric Motor Repair Shop',
  'Electric Motor Store',
  'Electric Utility Company',
  'Electric Utility Manufacturer',
  'Electric Vehicle Charging Station',
  'Electrical Appliance Wholesaler',
  'Electrical Engineer',
  'Electrical Equipment Supplier',
  'Electrical Installation Service',
  'Electrical Repair Shop',
  'Electrical Substation',
  'Electrical Supply Store',
  'Electrical Wholesaler',
  'Electrician',
  'Electrolysis Hair Removal Service',
  'Electronic Engineer',
  'Electronic Equipment Service',
  'Electronic Parts Supplier',
  'Electronics Accessories Wholesaler',
  'Electronics Company',
  'Electronics Engineer',
  'Electronics Exporter',
  'Electronics Hire Shop',
  'Electronics Manufacturer',
  'Electronics Repair Shop',
  'Electronics Store',
  'Electronics Vending Machine',
  'Electronics Wholesaler',
  'Elementary School',
  'Elevator Manufacturer',
  'Elevator Service',
  'Email Marketing',
  'Embassy',
  'Embossing Service',
  'Embroidery Service',
  'Embroidery Shop',
  'Emergency Call Booth',
  'Emergency Care Physician',
  'Emergency Care Service',
  'Emergency Dental Service',
  'Emergency Locksmith Service',
  'Emergency Management Ministry',
  'Emergency Medicine',
  'Emergency Roadside Service',
  'Emergency Room',
  'Emergency Training School',
  'Emergency Training',
  'Emergency Veterinarian Service',
  'Emissions Inspection',
  'Empanada Restaurant',
  'Employment Agency',
  'Employment Attorney',
  'Employment Center',
  'Employment Consultant',
  'Employment Lawyer',
  'Employment Search Service',
  'Endocrinologist',
  'Endodontist',
  'Endoscopist',
  'Energy Consulting',
  'Energy Equipment and Solutions',
  'Energy Supplier',
  'Engine Rebuilding Service',
  'Engineer',
  'Engineering Consultant',
  'Engineering School',
  'Engineering Service',
  'English Language Camp',
  'English Language Instructor',
  'English Language School',
  'English Restaurant',
  'Engraver',
  'Enterprise Startup',
  'Entertainer',
  'Entertainment Agency',
  'Entertainment Law',
  'Entertainment Service',
  'Entrepreneur',
  'Envelope Supplier',
  'Environment Office',
  'Environment Renewable Natural Resources',
  'Environmental Conservation',
  'Environmental Consultant',
  'Environmental Engineer',
  'Environmental Health Service',
  'Environmental Law',
  'Environmental Organization',
  'Environmental Protection Organization',
  'Episcopal Church',
  'Equestrian Club',
  'Equestrian Facility',
  'Equestrian Resort',
  'Equestrian Store',
  'Equipment Exporter',
  'Equipment Importer',
  'Equipment Rental Agency',
  'Equipment Service & Supply',
  'Equipment Supplier',
  'Eritrean Restaurant',
  'Escrow Service',
  'Espresso Bar',
  'Estate Appraiser',
  'Estate Liquidator',
  'Estate Planning Attorney',
  'Estate Planning Law',
  'Estate Planning',
  'Ethiopian Restaurant',
  'Ethnic Grocery Store',
  'Ethnic Restaurant',
  'Ethnographic Museum',
  'European Institution',
  'European Restaurant',
  'Evangelical Church',
  'Evening Dress Rental Service',
  'Evening School',
  'Event Management Company',
  'Event Planner',
  'Event Technology Service',
  'Event Ticket Seller',
  'Event Venue',
  'Excavating Contractor',
  'Excavation & Wrecking',
  'Exchange Program',
  'Executive Search Firm',
  'Executive Search',
  'Executive Suite Rental Agency',
  'Executor',
  'Exercise Equipment Store',
  'Exhibit',
  'Exhibition Planner',
  'Exhibition and Trade Centre',
  'Exotic Car Rental',
  'Exporter',
  'Extended Stay Hotel',
  'Eye Care Center',
  'Eye Doctor',
  'Eyewear',
  'FMCG Goods Wholesaler',
  'FMCG Manufacturer',
  'Fabric Product Manufacturer',
  'Fabric Store',
  'Fabric Wholesaler',
  'Fabrication Engineer',
  'Facial Spa',
  'Factory Equipment Supplier',
  'Faculty of Arts',
  'Faculty of Law',
  'Faculty of Pharmacy',
  'Faculty of Psychology',
  'Faculty of Science',
  'Faculty of Sports',
  'Fair Trade Organization',
  'Fairground',
  'Falafel Restaurant',
  'Family Counselor',
  'Family Day Care Service',
  'Family Doctor',
  'Family Hotel',
  'Family Law Attorney',
  'Family Medicine Practice',
  'Family Practice Physician',
  'Family Restaurant',
  'Family Service Center',
  'Family Style Restaurant',
  'Farm Bureau',
  'Farm Equipment Repair Service',
  'Farm Equipment Supplier',
  'Farm Household Tour',
  'Farm School',
  'Farm Shop',
  'Farm',
  'Farmers\' Market',
  'Farmstay',
  'Fashion Accessories Store',
  'Fashion Design School',
  'Fashion Designer',
  'Fast Food Restaurant',
  'Fastener Supplier',
  'Favela',
  'Fax Service',
  'Federal Agency for Technical Relief',
  'Federal Credit Union',
  'Federal Government Office',
  'Federal Police',
  'Federal Reserve Bank',
  'Feed Manufacturer',
  'Felt Boots Store',
  'Fence Contractor',
  'Fence Supply Store',
  'Fencing Salon',
  'Fencing School',
  'Feng Shui Consultant',
  'Feng Shui Shop',
  'Ferris wheel',
  'Ferry & Boat',
  'Ferry Service',
  'Fertility Clinic',
  'Fertility Physician',
  'Fertility',
  'Fertilizer Supplier',
  'Festival Hall',
  'Festival',
  'Fiat dealer',
  'Fiber Optic Products Supplier',
  'Fiberglass Repair Service',
  'Fiberglass Supplier',
  'Figurine Shop',
  'Filipino Restaurant',
  'Film Production Company',
  'Film and Photograph Library',
  'Filtration Plant',
  'Finance Broker',
  'Financial Advisor',
  'Financial Aid',
  'Financial Audit',
  'Financial Consultant',
  'Financial Institution',
  'Financial Planner',
  'Financial Planning',
  'Financial Service',
  'Fine Dining Restaurant',
  'Fingerprinting Service',
  'Finishing Materials Supplier',
  'Finnish Restaurant',
  'Fire Alarm Supplier',
  'Fire Damage Restoration Service',
  'Fire Department Equipment Supplier',
  'Fire Fighters Academy',
  'Fire Protection Consultant',
  'Fire Protection Equipment Supplier',
  'Fire Protection Service',
  'Fire Protection System Supplier',
  'Fire Protection',
  'Fire Station',
  'Firearms Academy',
  'Fireplace Manufacturer',
  'Fireplace Store',
  'Fireplaces',
  'Firewood Supplier',
  'Fireworks Retailer',
  'Fireworks Store',
  'Fireworks Supplier',
  'First Aid Class',
  'First Aid Service',
  'First Aid Station',
  'Fish & Chips Restaurant',
  'Fish & Chips Shop',
  'Fish Farm',
  'Fish Processing',
  'Fish Spa',
  'Fish Store',
  'Fish and Chips Takeaway',
  'Fishing Camp',
  'Fishing Charter',
  'Fishing Club',
  'Fishing Pier',
  'Fishing Pond',
  'Fishing Store',
  'Fishing',
  'Fitness Equipment Wholesaler',
  'Fitted Furniture Supplier',
  'Flag Store',
  'Flamenco Dance Store',
  'Flamenco School',
  'Flamenco Theater',
  'Flavours Fragrances and Aroma Supplier',
  'Flea Market',
  'Flight School',
  'Floating Market',
  'Floor Refinishing Service',
  'Floor Sanding and Polishing Service',
  'Flooring Contractor',
  'Flooring Store',
  'Floridian Restaurant',
  'Florist',
  'Flour Mill',
  'Flower Delivery',
  'Flower Designer',
  'Flower Market',
  'Foam Rubber Producer',
  'Foam Rubber Supplier',
  'Folk High School',
  'Fondue Restaurant',
  'Food & Beverage Service & Distribution',
  'Food Bank',
  'Food Broker',
  'Food Consultant',
  'Food Court',
  'Food Machinery Supplier',
  'Food Manufacturer',
  'Food Manufacturing Supply',
  'Food Processing Company',
  'Food Processing Equipment',
  'Food Producer',
  'Food Products Supplier',
  'Food Seasoning Manufacturer',
  'Food Stand',
  'Food Truck',
  'Food and Beverage Consultant',
  'Food and Beverage Exporter',
  'Foot Bath',
  'Foot Care',
  'Foot Massage Parlor',
  'Football Club',
  'Footwear Wholesaler',
  'Ford Dealer',
  'Foreclosure Service',
  'Foreign Consulate',
  'Foreign Exchange Students Organization',
  'Foreign Languages Program School',
  'Foreign Trade Consultant',
  'Foreman Builders Association',
  'Forensic Consultant',
  'Forestry & Logging',
  'Forestry Service',
  'Forklift Dealer',
  'Forklift Rental Service',
  'Formal Wear Store',
  'Formal Wear',
  'Fortress',
  'Fortune Telling Services',
  'Foster Care Service',
  'Foundation',
  'Foundry',
  'Fountain Contractor',
  'Foursquare Church',
  'Franchising Service',
  'Fraternal Organization',
  'Free Clinic',
  'Free Parking Lot',
  'Freestyle Wrestling',
  'Freight Forwarding Service',
  'French Language School',
  'French Restaurant',
  'French Steakhouse Restaurant',
  'Fresh Food Market',
  'Fried Chicken Restaurant',
  'Fried Chicken Takeaway',
  'Friends Church',
  'Frituur',
  'Frozen Dessert Supplier',
  'Frozen Food Manufacturer',
  'Frozen Food Store',
  'Frozen Yogurt Shop',
  'Fruit & Vegetable Store',
  'Fruit Parlor',
  'Fruit Wholesaler',
  'Fruit and Vegetable Processing',
  'Fruit and Vegetable Store',
  'Fruit and Vegetable Wholesaler',
  'Fruits Wholesaler',
  'Fu Jian Restaurant',
  'Fuel Supplier',
  'Fugu Restaurant',
  'Fujian Restaurant',
  'Full Dress Rental Service',
  'Full Gospel Church',
  'Function Room Facility',
  'Fund Management Company',
  'Funeral Director',
  'Funeral Home',
  'Funeral Service',
  'Fur Coat Shop',
  'Fur Manufacturer',
  'Fur Service',
  'Furnace Parts Supplier',
  'Furnace Repair Service',
  'Furnace Store',
  'Furnished Apartment Building',
  'Furniture Accessories Supplier',
  'Furniture Accessories',
  'Furniture Maker',
  'Furniture Manufacturer',
  'Furniture Rental Service',
  'Furniture Repair Shop',
  'Furniture Repair',
  'Furniture Store',
  'Furniture Wholesaler',
  'Fusion Restaurant',
  'Futon Store',
  'Futsal Court',
  'GMC Dealer',
  'GPS Supplier',
  'Galician Restaurant',
  'Game Store',
  'Garage Builder',
  'Garage Door Service',
  'Garage Door Supplier',
  'Garbage Collection Service',
  'Garbage Dump Service',
  'Garbage Dump',
  'Garden Building Supplier',
  'Garden Center',
  'Garden Furniture Shop',
  'Garden Machinery Supplier',
  'Garden',
  'Gardener',
  'Garment Exporter',
  'Gas & Chemical Service',
  'Gas Company',
  'Gas Cylinders Supplier',
  'Gas Engineer',
  'Gas Installation Service',
  'Gas Logs Supplier',
  'Gas Shop',
  'Gas Station',
  'Gasfitter',
  'Gasket Manufacturer',
  'Gastroenterologist',
  'Gastrointestinal Surgeon',
  'Gastropub',
  'Gazebo Builder',
  'Gemologist',
  'Genealogist',
  'General Contractor',
  'General Hospital',
  'General Litigation',
  'General Practice Attorney',
  'General Practitioner',
  'General Register Office',
  'General Store',
  'Generator Shop',
  'Geneticist',
  'Geography and History Faculty',
  'Geologic Service',
  'Geological Research Company',
  'Geological Service',
  'Geologist',
  'Georgian Restaurant',
  'Geotechnical Engineer',
  'German Language School',
  'German Restaurant',
  'Gerontologist',
  'Ghost Town',
  'Gift Basket Store',
  'Gift Shop',
  'Gift Wrap Store',
  'Girls\' High School',
  'Glass & Mirror Shop',
  'Glass Block Supplier',
  'Glass Blower',
  'Glass Cutting Service',
  'Glass Engraver',
  'Glass Etching Service',
  'Glass Industry',
  'Glass Manufacturer',
  'Glass Merchant',
  'Glass Products',
  'Glass Repair Service',
  'Glass Service',
  'Glass Shop',
  'Glassware Manufacturer',
  'Glassware Store',
  'Glassware Wholesaler',
  'Glazier',
  'Gluten-Free Restaurant',
  'Go Karting',
  'Go-Kart Track',
  'Gold Dealer',
  'Gold Mining Company',
  'Goldfish Store',
  'Goldsmith',
  'Golf Cart Dealer',
  'Golf Club',
  'Golf Course Builder',
  'Golf Course',
  'Golf Driving Range',
  'Golf Instructor',
  'Golf Resort',
  'Gospel Church',
  'Gourmet Grocery Store',
  'Government College',
  'Government Economic Program',
  'Government Hospital',
  'Government Office',
  'Government Organization',
  'Government School',
  'Governmental Law',
  'Graduate School',
  'Graffiti Removal Service',
  'Grain Elevator',
  'Grammar School',
  'Grand Cafe',
  'Grande Ecole',
  'Granite Supplier',
  'Graphic Design',
  'Graphic Designer',
  'Gravel Pit',
  'Gravel Plant',
  'Greco Roman Wrestling',
  'Greek Orthodox Church',
  'Greek Restaurant',
  'Green Energy Supplier',
  'Greengrocer',
  'Greenhouse',
  'Greeting Card Shop',
  'Greyhound Stadium',
  'Grill Store',
  'Grill',
  'Grocery Delivery Service',
  'Grocery Store',
  'Ground Self Defense Force',
  'Group Accommodation',
  'Group Home',
  'Guardia Civil',
  'Guardia Di Finanza Police',
  'Guatemalan Restaurant',
  'Guest House',
  'Gui Zhou Restaurant',
  'Guitar Instructor',
  'Guitar Store',
  'Gun Club',
  'Gun Range',
  'Gun Shop',
  'Gun Store',
  'Gurudwara',
  'Gutter Cleaning Service',
  'Gym',
  'Gymnasium Cz',
  'Gymnasium School',
  'Gymnastics Center',
  'Gymnastics Club',
  'Gypsum Product Supplier',
  'Gyro Restaurant',
  'Gyudon Restaurant',
  'HEALTH, WELLNESS, AND BEAUTY',
  'HIV Testing Center',
  'HVAC Contractor',
  'Haberdashery',
  'Hair Extension Technician',
  'Hair Extensions Supplier',
  'Hair Removal Service',
  'Hair Removal',
  'Hair Replacement Service',
  'Hair Replacement',
  'Hair Salon',
  'Hair Transplantation Clinic',
  'Hairdresser',
  'Hairpieces & Extensions',
  'Haitian Restaurant',
  'Hakka Restaurant',
  'Halal Restaurant',
  'Halotherapy',
  'Ham Shop',
  'Hamburger Restaurant',
  'Hammam',
  'Hand Surgeon',
  'Handbags Shop',
  'Handball Club',
  'Handball Court',
  'Handicapped Transportation Service',
  'Handicraft Exporter',
  'Handicraft Fair',
  'Handicraft Museum',
  'Handicraft School',
  'Handicraft',
  'Handicrafts Wholesaler',
  'Handwriting Service',
  'Handyman',
  'Hang Gliding Center',
  'Hardware & Tools Service',
  'Hardware Store',
  'Hardware Training Institute',
  'Harley-Davidson Dealer',
  'Hat Shop',
  'Haunted House',
  'Haute Couture Fashion House',
  'Haute French Restaurant',
  'Hawaiian Goods Store',
  'Hawaiian Restaurant',
  'Hawker Centre',
  'Hawker Stall',
  'Hay Supplier',
  'Head Start Center',
  'Health Agency',
  'Health Club',
  'Health Consultant',
  'Health Food Restaurant',
  'Health Food Store',
  'Health Insurance Agency',
  'Health Resort',
  'Health Spa',
  'Health and Beauty Shop',
  'Healthcare Administrator',
  'Hearing Aid Provider',
  'Hearing Aid Repair Service',
  'Hearing Aid Store',
  'Heart Hospital',
  'Heating Contractor',
  'Heating Equipment Supplier',
  'Heating Oil Supplier',
  'Heating, Ventilating & Air Conditioning',
  'Height Works',
  'Helicopter Charter',
  'Helicopter Tour Agency',
  'Heliport',
  'Helium Gas Supplier',
  'Helpline',
  'Hematologist',
  'Hepatologist',
  'Herb Shop',
  'Herbal Medicine Store',
  'Herbal Shop',
  'Herbalist',
  'Heritage Building',
  'Heritage Museum',
  'Heritage Preservation',
  'High Ropes Course',
  'High School',
  'Higher Secondary School',
  'Highway Patrol',
  'Highway',
  'Hiking Area',
  'Himalayan Restaurant',
  'Hindu Priest',
  'Hindu Temple',
  'Hip Hop Dance Class',
  'Hispanic Church',
  'Historical Landmark',
  'Historical Place Museum',
  'Historical Place',
  'Historical Society',
  'History Museum',
  'Hoagie Restaurant',
  'Hobby Store',
  'Hockey Club',
  'Hockey Field',
  'Hockey Rink',
  'Hockey Supply Store',
  'Holding Company',
  'Holiday Accommodation Service',
  'Holiday Apartment Rental',
  'Holiday Apartment',
  'Holiday Home',
  'Holiday Park',
  'Holiness Church',
  'Holistic Medicine Practitioner',
  'Home Audio Store',
  'Home Automation Company',
  'Home Builder',
  'Home Cinema Installation',
  'Home Decor',
  'Home Goods Store',
  'Home Hairdresser',
  'Home Health Care Service',
  'Home Health Care',
  'Home Help Service Agency',
  'Home Help',
  'Home Improvement Store',
  'Home Improvement',
  'Home Inspection',
  'Home Inspector',
  'Home Insurance Agency',
  'Home Security',
  'Home Theater Store',
  'Home Window Service',
  'Homekill Service',
  'Homeless Service',
  'Homeless Shelter',
  'Homeopath',
  'Homeopathic Pharmacy',
  'Homeowners\' Association',
  'Homestay',
  'Honda Dealer',
  'Honduran Restaurant',
  'Honey Farm',
  'Hong Kong Restaurant',
  'Hong Kong Style Fast Food Restaurant',
  'Horse Boarding Stable',
  'Horse Breeder',
  'Horse Rental Service',
  'Horse Riding Field',
  'Horse Riding School',
  'Horse Trailer Dealer',
  'Horse Trainer',
  'Horse Transport Supplier',
  'Horseback Riding Service',
  'Horses',
  'Horseshoe Smith',
  'Horsestable Studfarm',
  'Hose Supplier',
  'Hospice',
  'Hospital Department',
  'Hospital Equipment and Supplies',
  'Hospital',
  'Hospital/Clinic',
  'Hospitalist',
  'Hospitality High School',
  'Hospitality Service',
  'Hospitality and Tourism School',
  'Host Club',
  'Hostel',
  'Hot Air Balloons',
  'Hot Bedstone Spa',
  'Hot Dog Joint',
  'Hot Dog Restaurant',
  'Hot Dog Stand',
  'Hot Pot Restaurant',
  'Hot Spring Hotel',
  'Hot Tub Repair Service',
  'Hot Tub Store',
  'Hot Water System Supplier',
  'Hotel Bar',
  'Hotel Management School',
  'Hotel Supply Service',
  'Hotel Supply Store',
  'Hotel',
  'Hotpot Restaurant',
  'House Cleaning Service',
  'House Clearance Service',
  'House Sitter Agency',
  'House Sitter',
  'Houseboat Rental Service',
  'Household Chemicals Supplier',
  'Household Goods Wholesaler',
  'Housewares',
  'Housing & Homeless Shelter',
  'Housing Assistance Service',
  'Housing Association',
  'Housing Authority',
  'Housing Complex',
  'Housing Cooperative',
  'Housing Development',
  'Housing Society',
  'Housing Utility Company',
  'Hua Gong Shop',
  'Hua Hui Market Place',
  'Hua Niao Market Place',
  'Hub Cap Supplier',
  'Huissier',
  'Human Resource Consulting',
  'Hunan Restaurant',
  'Hungarian Restaurant',
  'Hunting Area',
  'Hunting Club',
  'Hunting Preserve',
  'Hunting Store',
  'Hunting and Fishing Store',
  'Hunting and Fishing',
  'Hydraulic Engineer',
  'Hydraulic Equipment Supplier',
  'Hydraulic Repair Service',
  'Hydroelectric Power Plant',
  'Hydroponics Equipment Supplier',
  'Hydrotherapy',
  'Hygiene Articles Wholesaler',
  'Hygiene Station',
  'Hypermarket',
  'Hypnosis/Hypnotherapy',
  'Hypnotherapy Service',
  'Hyundai Dealer',
  'IP & Internet Law',
  'IT Services',
  'IUP',
  'Ice Cream Equipment Supplier',
  'Ice Cream Parlor',
  'Ice Cream Shop',
  'Ice Hockey Club',
  'Ice Machines',
  'Ice Skating Club',
  'Ice Skating Instructor',
  'Ice Skating Rink',
  'Ice Skating',
  'Ice Supplier',
  'Icelandic Restaurant',
  'Icse School',
  'Idol Manufacturer',
  'Image Consultant',
  'Imax Theater',
  'Immigration & Naturalization Service',
  'Immigration Attorney',
  'Immigration Detention Centre',
  'Immigration Law',
  'Immunodermatologist',
  'Immunologist',
  'Impermeabilization Service',
  'Import Export Company',
  'Importer',
  'Incense Supplier',
  'Incineration Plant',
  'Income Protection Insurance',
  'Income Tax Help Association',
  'Independent Church',
  'Indian Grocery Store',
  'Indian Muslim Restaurant',
  'Indian Restaurant',
  'Indo Chinese Restaurant',
  'Indonesian Restaurant',
  'Indoor Cycling',
  'Indoor Golf Course',
  'Indoor Lodging',
  'Indoor Playground',
  'Indoor Snowcenter',
  'Indoor Swimming Pool',
  'Industrial Area',
  'Industrial Chemicals Wholesaler',
  'Industrial Consultant',
  'Industrial Design Company',
  'Industrial Door Supplier',
  'Industrial Engineer',
  'Industrial Engineers Association',
  'Industrial Equipment Supplier',
  'Industrial Framework Supplier',
  'Industrial Gas Supplier',
  'Industrial Real Estate Agency',
  'Industrial Supermarket',
  'Industrial Technical Engineers Association',
  'Industrial Vacuum Equipment Supplier',
  'Infectious Disease Physician',
  'Infiniti Dealer',
  'Information Bureau',
  'Information Services',
  'Inn',
  'Insolvency Service',
  'Institute of Geography and Statistics',
  'Institute of Technology',
  'Instrumentation Engineer',
  'Insulation Contractor',
  'Insulation Materials Store',
  'Insulator Supplier',
  'Insurance Agency',
  'Insurance Agent',
  'Insurance Attorney',
  'Insurance Broker',
  'Insurance Company',
  'Insurance School',
  'Intellectual Property Registry',
  'Interdenominational Church',
  'Interior Architect Office',
  'Interior Construction Contractor',
  'Interior Designer',
  'Interior Door',
  'Interior Fitting Contractor',
  'Interior Plant Service',
  'Internal Medicine Ward',
  'Internal Medicine',
  'International Airport',
  'International Law',
  'International Restaurant',
  'International School',
  'International Trade Consultant',
  'Internet Cafe',
  'Internet Marketing Service',
  'Internet Service Provider',
  'Internet Shop',
  'Internist',
  'Inventory Control Service',
  'Investing Service',
  'Investment Bank',
  'Investment Company',
  'Investment Service',
  'Invitation Printing Service',
  'Irish Goods Store',
  'Irish Pub',
  'Irish Restaurant',
  'Iron Steel Contractor',
  'Iron Ware Dealer',
  'Iron Works',
  'Irrigation Equipment Supplier',
  'Island',
  'Israeli Restaurant',
  'Isuzu Dealer',
  'Italian Grocery Store',
  'Italian Restaurant',
  'Izakaya Restaurant',
  'Jaguar Dealer',
  'Jain Temple',
  'Jamaican Restaurant',
  'Janitorial Equipment Supplier',
  'Janitorial Service',
  'Japanese Cheap Sweets Shop',
  'Japanese Confectionary Shop',
  'Japanese Curry Restaurant',
  'Japanese Delicatessen',
  'Japanese Grocery Store',
  'Japanese Hot Pot Restaurant',
  'Japanese Inn With Hot Spring',
  'Japanese Inns',
  'Japanese Language Instructor',
  'Japanese Prefecture Government Office',
  'Japanese Regional Restaurant',
  'Japanese Restaurant',
  'Japanese Steakhouse',
  'Japanese Sweets Restaurant',
  'Japanized Western Restaurant',
  'Jazz Club',
  'Jeans Shop',
  'Jeep Dealer',
  'Jehovah\'s Witness Kingdom Hall',
  'Jeweler',
  'Jewellery Manufacturer',
  'Jewelry Appraiser',
  'Jewelry Buyer',
  'Jewelry Designer',
  'Jewelry Engraver',
  'Jewelry Equipment Supplier',
  'Jewelry Exporter',
  'Jewelry Repair Service',
  'Jewelry Store',
  'Jewelry Supplier',
  'Jewish Restaurant',
  'Jiang Su Restaurant',
  'Joiner',
  'Judicial Auction',
  'Judicial Scrivener',
  'Judo Club',
  'Judo School',
  'Juice Shop',
  'Jujitsu School',
  'Junior College',
  'Junior High School',
  'Junk Dealer',
  'Junk Store',
  'Junkyard',
  'Justice Department',
  'Jute Exporter',
  'Jute Mill',
  'Juvenile Detention Center',
  'Juvenile Law',
  'Kabaddi Club',
  'Kaiseki Restaurant',
  'Karaoke Bar',
  'Karaoke Equipment Rental Service',
  'Karaoke',
  'Karate Club',
  'Karate School',
  'Kashmiri Restaurant',
  'Katsudon Restaurant',
  'Kawasaki Motorcycle Dealer',
  'Kazakhstani Restaurant',
  'Kebab Restaurant',
  'Kebab Shop',
  'Kennel',
  'Kerosene Supplier',
  'Key Duplication Service',
  'Kia Dealer',
  'Kickboxing School',
  'Kilt Shop and Hire',
  'Kimono Store',
  'Kindergarten',
  'Kinesiologist',
  'Kingdom Hall',
  'Kiosk',
  'Kitchen Construction',
  'Kitchen Furniture Store',
  'Kitchen Remodeler',
  'Kitchen Supply Store',
  'Kitchen/Cooking',
  'Kite Shop',
  'Knife Manufacturing',
  'Knife Store',
  'Knit Shop',
  'Knitting Instructor',
  'Knitwear Manufacturer',
  'Korean Barbecue Restaurant',
  'Korean Beef Restaurant',
  'Korean Church',
  'Korean Grocery Store',
  'Korean Restaurant',
  'Korean Rib Restaurant',
  'Kosher Grocery Store',
  'Kosher Restaurant',
  'Kung Fu School',
  'Kurdish Restaurant',
  'Kushiage and Kushikatsu Restaurant',
  'Kushiyaki Restaurant',
  'Kyoto Style Japanese Restaurant',
  'LASIK Surgeon',
  'LAW AND FINANCE',
  'LOCAL SERVICE',
  'LPG Conversion',
  'Labor & Employment Law',
  'Labor Relations Attorney',
  'Labor Union',
  'Laboratory Equipment Supplier',
  'Laboratory Equipment',
  'Laboratory',
  'Labour Club',
  'Lactation Services',
  'Ladder Supplier',
  'Laminating Equipment Supplier',
  'Lamination Service',
  'Lamp Repair Service',
  'Lamp Shade Supplier',
  'Land Allotment',
  'Land Planning Authority',
  'Land Reform Institute',
  'Land Registry Office',
  'Land Rover Dealer',
  'Land Surveying Office',
  'Land Surveyor',
  'Landmark',
  'Landscape Architect',
  'Landscape Designer',
  'Landscape Lighting Designer',
  'Landscaper',
  'Landscaping Supply Store',
  'Landscaping',
  'Language School',
  'Laotian Restaurant',
  'Lapidary',
  'Laser Cutting Service',
  'Laser Equipment Supplier',
  'Laser Eye Surgery/Lasik',
  'Laser Hair Removal Service',
  'Laser Hair Removal',
  'Laser Tag Center',
  'Laser Tag',
  'Late Night Restaurant',
  'Latin American Restaurant',
  'Laundromat',
  'Laundry Service',
  'Laundry',
  'Law Book Store',
  'Law Enforcement',
  'Law Firm',
  'Law Library',
  'Law Practice',
  'Law School',
  'Lawn Bowls Club',
  'Lawn Care Service',
  'Lawn Equipment Rental Service',
  'Lawn Irrigation Equipment Supplier',
  'Lawn Mower Repair Service',
  'Lawn Mower Store',
  'Lawn Sprinkler System Contractor',
  'Lawyer',
  'Lawyers Association',
  'Leagues Club',
  'Learner Driver Training Area',
  'Learning Center',
  'Leasing Service',
  'Leather Cleaning Service',
  'Leather Coats Store',
  'Leather Exporter',
  'Leather Goods Manufacturer',
  'Leather Goods Store',
  'Leather Goods Supplier',
  'Leather Goods Wholesaler',
  'Leather Repair Service',
  'Leather Wholesaler',
  'Lebanese Restaurant',
  'Legal Affairs Bureau',
  'Legal Aid Office',
  'Legal Services',
  'Leisure Centre',
  'Lexus Dealer',
  'Library',
  'License Bureau',
  'License Plate Frames Supplier',
  'Lido',
  'Life Coach',
  'Life Insurance Agency',
  'Lifestyle Services',
  'Light Bulb Supplier',
  'Lighting Consultant',
  'Lighting Contractor',
  'Lighting Fixtures',
  'Lighting Manufacturer',
  'Lighting Store',
  'Lighting Wholesaler',
  'Limo Service',
  'Limousine Service',
  'Lincoln Mercury Dealer',
  'Line Marking Service',
  'Linens Store',
  'Linoleum Store',
  'Lion Dance Troupe',
  'Liquidator',
  'Liquor Store',
  'Liquor Wholesaler',
  'Literacy Program',
  'Lithuanian Restaurant',
  'Little League Club',
  'Little League Field',
  'Live & Raw Food Restaurant',
  'Live Music Bar',
  'Live Music Venue',
  'Livery Company',
  'Livestock Auction House',
  'Livestock Breeder',
  'Livestock Dealer',
  'Livestock Producer',
  'Loan Agency',
  'Loans',
  'Lobbyist',
  'Lobster Restaurant',
  'Local Education',
  'Local Government Office',
  'Local History Museum',
  'Local Medical Services',
  'Locks Supplier',
  'Locksmith',
  'Lodge',
  'Lodging',
  'Log Cabins',
  'Log Home Builder',
  'Logging Contractor',
  'Logistics Service',
  'Loss Adjuster',
  'Lost Property Office',
  'Lottery Retailer',
  'Lottery Shop',
  'Lounge',
  'Low Emission Zone',
  'Low Income Housing Program',
  'Luggage Repair Service',
  'Luggage Service',
  'Luggage Store',
  'Luggage Wholesaler',
  'Lumber Store',
  'Lunch Restaurant',
  'Lutheran Church',
  'Luxury Hotel',
  'Lyceum',
  'Lymph Drainage Therapist',
  'MRI Center',
  'Machine Construction',
  'Machine Knife Supplier',
  'Machine Maintenance',
  'Machine Repair Service',
  'Machine Shop',
  'Machine Workshop',
  'Machinery Parts Manufacturer',
  'Machining Manufacturer',
  'Macrobiotic Restaurant',
  'Madrilian Restaurant',
  'Magazine Publisher',
  'Magazine Store',
  'Magic Store',
  'Magician',
  'Mah Jong House',
  'Maid & Butler',
  'Mailbox Rental Service',
  'Mailbox Supplier',
  'Mailing Machine Supplier',
  'Mailing Service',
  'Main Customs Office',
  'Make-up Artist',
  'Makeup Artist',
  'Malay Restaurant',
  'Malaysian Restaurant',
  'Male Hospital',
  'Malpractice Law',
  'Maltese Restaurant',
  'Mammography Service',
  'Management School',
  'Management Service',
  'Mandarin Restaurant',
  'Manor House',
  'Manufactured Home Transporter',
  'Manufacturer',
  'Manufacturing',
  'Maori Organization',
  'Map Store',
  'Mapping Service',
  'Marae',
  'Marble Contractor',
  'Marble Supplier',
  'Marche Restaurant',
  'Marina',
  'Marine Engineer',
  'Marine Equipment',
  'Marine Self Defense Force',
  'Marine Supply Store',
  'Marine Surveyor',
  'Maritime Law',
  'Maritime Museum',
  'Market Operator',
  'Market Researcher',
  'Market',
  'Marketing Agency',
  'Marketing Consultant',
  'Markmens Clubhouse',
  'Marquee Hire Service',
  'Marriage Celebrant',
  'Marriage Counselor',
  'Marriage License Bureau',
  'Martial Arts Club',
  'Martial Arts School',
  'Martial Arts Supply Store',
  'Martial Arts',
  'Masonic Center',
  'Masonry Contractor',
  'Masonry Supply Store',
  'Masonry',
  'Massage School',
  'Massage Spa',
  'Massage Supply Store',
  'Massage Therapist',
  'Massage',
  'Match Box Manufacturer',
  'Material Handling Equipment Supplier',
  'Maternity Clinic',
  'Maternity Hospital',
  'Maternity Store',
  'Mathematics School',
  'Mattress Manufacturing',
  'Mattress Store',
  'Mattress Wholesale',
  'Mattresses & Bedding',
  'Mausoleum Builder',
  'Mazda Dealer',
  'Meal Delivery',
  'Meal Takeaway',
  'Measuring Instruments Supplier',
  'Meat Dish Restaurant',
  'Meat Packer',
  'Meat Processor',
  'Meat Products',
  'Meat Wholesaler',
  'Mechanic',
  'Mechanical Contractor',
  'Mechanical Engineer',
  'Mechanical Plant',
  'Media Company',
  'Media Consultant',
  'Media House',
  'Media and Information Sciences Faculty',
  'Mediation Service',
  'Medical Billing Service',
  'Medical Book Store',
  'Medical Center',
  'Medical Certificate Service',
  'Medical Clinic',
  'Medical Diagnostic Imaging Center',
  'Medical Equipment Manufacturer',
  'Medical Equipment Supplier',
  'Medical Examiner',
  'Medical Group',
  'Medical Lab',
  'Medical Laboratory',
  'Medical Law',
  'Medical Office',
  'Medical Research',
  'Medical School',
  'Medical Spa',
  'Medical Supplies',
  'Medical Supply Store',
  'Medical Technology Manufacturer',
  'Medical Transcription Service',
  'Medicine Exporter',
  'Meditation Center',
  'Meditation Instructor',
  'Mediterranean Restaurant',
  'Meeting Planning Service',
  'Meeting Room',
  'Mehandi Class',
  'Mehndi Designer',
  'Memorial Estate',
  'Memorial Park',
  'Mennonite Church',
  'Mens Tailor',
  'Mental Health Clinic',
  'Mental Health Service',
  'Men\'s Clothing Store',
  'Mercantile Development',
  'Mercedes Benz Dealer',
  'Merchandising Service',
  'Messianic Synagogue',
  'Metal Construction Company',
  'Metal Detecting Equipment Supplier',
  'Metal Fabricator',
  'Metal Finisher',
  'Metal Heat Treating Service',
  'Metal Industry Suppliers',
  'Metal Machinery Supplier',
  'Metal Polishing Service',
  'Metal Processing Company',
  'Metal Stamping Service',
  'Metal Supplier',
  'Metal Working Shop',
  'Metal Workshop',
  'Metallurgy Company',
  'Metals',
  'Metalware Dealer',
  'Metalware Producer',
  'Metaphysical Supply Store',
  'Methodist Church',
  'Metropolitan Train Company',
  'Mexican Goods Store',
  'Mexican Grocery Store',
  'Mexican Restaurant',
  'Mfr',
  'Microbiologist',
  'Microwave Oven Repair Service',
  'Mid-Atlantic Restaurant (US)',
  'Middle Eastern Restaurant',
  'Middle School',
  'Midwife',
  'Militar Archive',
  'Militar Residence',
  'Military Barracks',
  'Military Base',
  'Military Board',
  'Military Cemetery',
  'Military Hospital',
  'Military Law',
  'Military Recruiting Office',
  'Military School',
  'Military Town',
  'Milk Delivery Service',
  'Mill',
  'Millwork Shop',
  'Mine',
  'Mineral Water Company',
  'Mineral Water Wholesale',
  'Miniature Golf Course',
  'Miniature Golf',
  'Miniatures Store',
  'Minibus Taxi Service',
  'Mining Company',
  'Mining Consultant',
  'Mining Engineer',
  'Mining Equipment',
  'Ministry of Education',
  'Mirror Shop',
  'Miso Cutlet Restaurant',
  'Missing Persons Organization',
  'Mission',
  'Mitsubishi Dealer',
  'Mobile Caterer',
  'Mobile Design',
  'Mobile Development',
  'Mobile Disco',
  'Mobile Hairdresser',
  'Mobile Home Dealer',
  'Mobile Home Park',
  'Mobile Home Rental Agency',
  'Mobile Home Supply Store',
  'Mobile Homes',
  'Mobile Money Agent',
  'Mobile Network Operator',
  'Mobile Phone Repair Shop',
  'Mobile Phone Shop',
  'Mobile Startup',
  'Mobility Equipment Supplier',
  'Model Car Play Area',
  'Model Design Company',
  'Model Portfolio Studio',
  'Model Shop',
  'Model Train Store',
  'Modeling Agency',
  'Modeling School',
  'Modern Art Museum',
  'Modern British Restaurant',
  'Modern European Restaurant',
  'Modern French Restaurant',
  'Modern Izakaya Restaurants',
  'Modular Home Builder',
  'Modular Home Dealer',
  'Mold Maker',
  'Molding Supplier',
  'Molecular Gastronomy Restaurant',
  'Monastery',
  'Money Order Service',
  'Money Transfer Service',
  'Mongolian Barbecue Restaurant',
  'Mongolian Restaurant',
  'Monjayaki Restaurant',
  'Monogramming Service',
  'Montessori School',
  'Monument Maker',
  'Monument',
  'Moped Dealer',
  'Moravian Church',
  'Moroccan Restaurant',
  'Mortgage Broker',
  'Mortgage Brokers',
  'Mortgage Lender',
  'Mortuary',
  'Mosque',
  'Motel',
  'Motor Scooter Dealer',
  'Motor Scooter Repair Shop',
  'Motor Vehicle Dealer',
  'Motorcycle Dealer',
  'Motorcycle Driving School',
  'Motorcycle Insurance Agency',
  'Motorcycle Parts Store',
  'Motorcycle Rental Agency',
  'Motorcycle Repair Shop',
  'Motorcycle Repair',
  'Motorcycle Shop',
  'Motorcycles',
  'Motoring Club',
  'Motorsports Store',
  'Mountain Biking',
  'Mountain Cabin',
  'Mountain Cable Car',
  'Mountaineering Class',
  'Mover',
  'Movie & Television Studio',
  'Movie Rental Kiosk',
  'Movie Rental Store',
  'Movie Studio',
  'Movie Theater',
  'Moving Company',
  'Moving Supply Store',
  'Moving and Storage Service',
  'Muay Thai Boxing Gym',
  'Muffler Shop',
  'Mulch Supplier',
  'Multimedia and Electronic Book Publisher',
  'Municipal Administration Office',
  'Municipal Corporation',
  'Municipal Department Agricultural Development',
  'Municipal Department Agriculture Food Supply',
  'Municipal Department Civil Defense',
  'Municipal Department Communication',
  'Municipal Department Finance',
  'Municipal Department Housing and Urban Development',
  'Municipal Department Science Technology',
  'Municipal Department Social Defense',
  'Municipal Department of Culture',
  'Municipal Department of Sports',
  'Municipal Department of Tourism',
  'Municipal Guard',
  'Municipal Health Department',
  'Municipal Office Education',
  'Municipal Social Development',
  'Museum of Space History',
  'Museum of Zoology',
  'Museum',
  'Music Box Store',
  'Music College',
  'Music Conservatory',
  'Music Instructor',
  'Music Lessons & Instruction',
  'Music Management and Promotion',
  'Music Producer',
  'Music Production',
  'Music Publisher',
  'Music School',
  'Music Store',
  'Musical Club',
  'Musical Instrument Manufacturer',
  'Musical Instrument Rental Service',
  'Musical Instrument Repair Shop',
  'Musical Instrument Store',
  'Musician and Composer',
  'Musician',
  'Musician/Band',
  'Mutton Barbecue Restaurant',
  'Nail Salon',
  'Nanny',
  'Nanotechnology Engineer',
  'National Forest',
  'National Health Foundation',
  'National Library',
  'National Museum',
  'National Park',
  'National Reserve',
  'Native American Goods Store',
  'Native American Restaurant',
  'Natural Foods Store',
  'Natural Gas Supplier',
  'Natural History Museum',
  'Natural Stone Exporter',
  'Natural Stone Supplier',
  'Natural Stone Wholesaler',
  'Nature Preserve',
  'Naturopathic Practitioner',
  'Naturopathic/Holistic',
  'Naval Base',
  'Navarraise Restaurant',
  'Nazarene Church',
  'Neapolitan Restaurant',
  'Needlework Shop',
  'Neighborhood',
  'Neon Sign Shop',
  'Neonatal Physician',
  'Nepalese Restaurant',
  'Nephrologist',
  'Netball Club',
  'Neurologist',
  'Neuropathologist',
  'Neurosurgeon',
  'Neurotologist',
  'New Age Church',
  'New American Restaurant',
  'New England Restaurant',
  'New Years Tree Market',
  'New Zealand Restaurant',
  'News Service',
  'Newspaper Advertising Department',
  'Newspaper Distribution',
  'Newspaper Publisher',
  'Newspaper',
  'Newsstand',
  'Nicaraguan Restaurant',
  'Night Club',
  'Night Market',
  'Nightlife',
  'Nissan Dealer',
  'Non Smoking Holiday Home',
  'Non-Denominational Church',
  'Non-Governmental Organization',
  'Non-Profit Organization',
  'Non-Profit',
  'Nondenominational Church',
  'Noodle House',
  'Noodle Shop',
  'North African Restaurant',
  'Northern Italian Restaurant',
  'Norwegian Restaurant',
  'Notaries Association',
  'Notary Public',
  'Notions Store',
  'Novelties Wholesaler',
  'Novelty Store',
  'Nuclear Engineer',
  'Nuclear Power Company',
  'Nuclear Power Plant',
  'Nuevo Latino Restaurant',
  'Numerologist',
  'Nurse Practitioner',
  'Nursery School',
  'Nursing Agency',
  'Nursing Association',
  'Nursing Home',
  'Nursing School',
  'Nursing',
  'Nut Store',
  'Nutritionist',
  'Nyonya Restaurant',
  'OBGYN',
  'Obanzai Restaurant',
  'Observation Deck',
  'Observatory',
  'Obstetrician-Gynecologist',
  'Obstetricians & Gynecologists',
  'Occupational Health Service',
  'Occupational Medical Physician',
  'Occupational Safety and Health',
  'Occupational Safety',
  'Occupational Therapist',
  'Oden Restaurant',
  'Off Roading Area',
  'Off Track Betting Shop',
  'Off-road Race Track',
  'Offal Barbecue Restaurant',
  'Offal Pot Cooking Restaurant',
  'Office Accessories Wholesaler',
  'Office Equipment Rental Service',
  'Office Equipment Repair Service',
  'Office Equipment Supplier',
  'Office Furniture Store',
  'Office Refurbishment Service',
  'Office Space Rental Agency',
  'Office Supplies',
  'Office Supply Store',
  'Office Supply Wholesaler',
  'Office of Vital Records',
  'Oil & Natural Gas Company',
  'Oil Change Service',
  'Oil Field Equipment Supplier',
  'Oil Lube & Filter Service',
  'Oil Refinery',
  'Oil Store',
  'Oil Wholesaler',
  'Oil and Gas Exploration Service',
  'Oilfield',
  'Okonomiyaki Restaurant',
  'Oldsmobile Dealer',
  'Olive Oil Bottling Company',
  'Olive Oil Cooperative',
  'Olive Oil Manufacturer',
  'Oncologist',
  'Opel dealer',
  'Open Air Museum',
  'Open University',
  'Opera Company',
  'Opera House',
  'Ophthalmologist',
  'Ophthalmology Clinic',
  'Optical Products Manufacturer',
  'Optical Wholesaler',
  'Optician',
  'Optometrist',
  'Oral Surgeon',
  'Orchard',
  'Orchestra',
  'Orchid Farm',
  'Orchid Grower',
  'Organ Donation and Tissue Bank',
  'Organic Drug Store',
  'Organic Farm',
  'Organic Food Store',
  'Organic Restaurant',
  'Organic Shop',
  'Organization',
  'Oriental Art Museum',
  'Oriental Goods Store',
  'Oriental Medicine Clinic',
  'Oriental Medicine Store',
  'Oriental Rug Store',
  'Orphan Asylum',
  'Orphanage',
  'Orthodontist',
  'Orthodox Church',
  'Orthodox Synagogue',
  'Orthopedic Clinic',
  'Orthopedic Shoe Store',
  'Orthopedic Surgeon',
  'Orthopedist',
  'Orthoptist',
  'Orthotics & Prosthetics Service',
  'Osteopath',
  'Osteopathic Physician',
  'Otolaryngologist',
  'Otolaryngology Clinic',
  'Otolaryngology Hospital',
  'Otologist',
  'Outboard Motor Store',
  'Outdoor Activity Organiser',
  'Outdoor Bath',
  'Outdoor Clothing and Equipment Shop',
  'Outdoor Equestrian Facility',
  'Outdoor Equipment Store',
  'Outdoor Furniture Store',
  'Outdoor Recreation',
  'Outdoor Sports Store',
  'Outdoor Swimming Pool',
  'Outdoor movie theater',
  'Outdoors',
  'Outerwear Store',
  'Outlet Mall',
  'Outlet Store',
  'Oxygen Cocktail Spot',
  'Oxygen Equipment Supplier',
  'Oyster Bar Restaurant',
  'Oyster Supplier',
  'Personal Website',
  'PR Firm',
  'PVC Industry',
  'PVC Windows Supplier',
  'Pachinko Parlor',
  'Pacific Northwest Restaurant (Canada)',
  'Pacific Northwest Restaurant (US)',
  'Pacific Rim Restaurant',
  'Packaging Company',
  'Packaging Machinery',
  'Packaging Supplies & Equipment',
  'Packaging Supply Store',
  'Padel Club',
  'Padel Court',
  'Paella Restaurant',
  'Pagoda',
  'Pain Control Clinic',
  'Pain Management Physician',
  'Pain Management',
  'Paint Manufacturer',
  'Paint Store',
  'Paint Stripping Company',
  'Paintball Center',
  'Paintball Store',
  'Paintball',
  'Painter',
  'Painting Lessons',
  'Painting Studio',
  'Painting',
  'Paintings Store',
  'Pakistani Restaurant',
  'Pallet Supplier',
  'Pan-Asian Restaurant',
  'Pan-Latin Restaurant',
  'Pancake Restaurant',
  'Paper Bag Supplier',
  'Paper Distributor',
  'Paper Exporter',
  'Paper Mill',
  'Paper Shredding Machine Supplier',
  'Paper Store',
  'Paraguayan Restaurant',
  'Paralegal Services Provider',
  'Parasailing Ride Service',
  'Parish',
  'Park & Ride',
  'Park',
  'Parking Garage',
  'Parking Lot for Bicycles',
  'Parking Lot for Motorcycles',
  'Parking Lot',
  'Parking',
  'Parkour Spot',
  'Parochial School',
  'Parsi Temple',
  'Part Time Daycare',
  'Party Center',
  'Party Equipment Rental Service',
  'Party Planner',
  'Party Store',
  'Party Supplies',
  'Passport & Visa Service',
  'Passport Agent',
  'Passport Office',
  'Passport Photo Processor',
  'Pasta Shop',
  'Pastry Shop',
  'Patent Attorney',
  'Patent Office',
  'Patent Trademark & Copyright Law',
  'Pathologist',
  'Patients Support Association',
  'Patio Enclosure Supplier',
  'Patisserie',
  'Patrol & Security',
  'Paving & Asphalt Service',
  'Paving Contractor',
  'Paving Materials Supplier',
  'Pawn Shop',
  'Payphone',
  'Payroll Service',
  'Pedestrian Zone',
  'Pediatric Cardiologist',
  'Pediatric Dentist',
  'Pediatric Ophthalmologist',
  'Pediatrician',
  'Pediatrics',
  'Peking Restaurant',
  'Pen Store',
  'Pennsylvania Dutch Restaurant',
  'Pension Office',
  'Pentecostal Church',
  'People S House',
  'Performance Venue',
  'Performing Arts Group',
  'Performing Arts Theater',
  'Perfume Store',
  'Perinatal Center',
  'Periodontist',
  'Permanent Make-up Clinic',
  'Persian Restaurant',
  'Persian/Iranian Restaurant',
  'Personal Coaching',
  'Personal Injury Attorney',
  'Personal Injury Law',
  'Personal Trainer',
  'Peruvian Restaurant',
  'Pest Control Service',
  'Pest Control',
  'Pet Adoption Service',
  'Pet Boarding Service',
  'Pet Breeder',
  'Pet Cafe',
  'Pet Cemetery',
  'Pet Friendly Accommodation',
  'Pet Funeral Service',
  'Pet Groomer',
  'Pet Moving Service',
  'Pet Service',
  'Pet Sitter',
  'Pet Store',
  'Pet Supply Store',
  'Pet Trainer',
  'Petrochemical Engineer',
  'Petroleum Products Company',
  'Petroleum Service',
  'Petting Zoo',
  'Pharmaceutical Company',
  'Pharmaceutical Lab',
  'Pharmaceutical Products Wholesaler',
  'Pharmacy',
  'Pharmacy/Drugstore',
  'Philharmonic Hall',
  'Phlebologist',
  'Pho Restaurant',
  'Phone Repair Service',
  'Photo Agency',
  'Photo Lab',
  'Photo Restoration Service',
  'Photo Shop',
  'Photocopiers Supplier',
  'Photographer',
  'Photographic Services & Equipment',
  'Photography Class',
  'Photography School',
  'Photography Service',
  'Photography Studio',
  'Physiatrist',
  'Physical Examination Center',
  'Physical Fitness Program',
  'Physical Fitness',
  'Physical Therapist',
  'Physical Therapy Clinic',
  'Physician Assistant',
  'Physician Referral Service',
  'Physiotherapy Equip Supplier',
  'Piano Bar',
  'Piano Instructor',
  'Piano Maker',
  'Piano Moving Service',
  'Piano Repair Service',
  'Piano Store',
  'Piano Tuning Service',
  'Pick Your Own Farm Produce',
  'Picnic Ground',
  'Picture Frame Renovating and Restoring Service',
  'Picture Frame Shop',
  'Pie Shop',
  'Pilates Studio',
  'Pile Driver',
  'Pilgrimage Place',
  'Pinatas Supplier',
  'Pinball Machine Supplier',
  'Pine Furniture Shop',
  'Pipe Supplier',
  'Piste Vtt',
  'Pizza Delivery',
  'Pizza Place',
  'Pizza Restaurant',
  'Pizza Takeaway',
  'Place of Worship',
  'Placenta Encapsulation',
  'Planetarium',
  'Plant Nursery',
  'Plant and Machinery Hire',
  'Plast Window Store',
  'Plasterer',
  'Plastic Bag Supplier',
  'Plastic Bags Wholesaler',
  'Plastic Fabrication Company',
  'Plastic Injection Molding Service',
  'Plastic Products Supplier',
  'Plastic Resin Manufacturer',
  'Plastic Surgeon',
  'Plastic Surgery Clinic',
  'Plastic Surgery',
  'Plastic Wholesaler',
  'Plastics',
  'Plating Service',
  'Play School',
  'Playground Equipment Supplier',
  'Playground',
  'Playgroup',
  'Plumber',
  'Plumbing Supply Store',
  'Plus Size Clothing Store',
  'Plywood Supplier',
  'Pneumatic Tools Supplier',
  'Podiatrist',
  'Police Academy',
  'Police Station',
  'Police Supply Store',
  'Polish Restaurant',
  'Political Organization',
  'Political Party',
  'Polo Club',
  'Polygraph Service',
  'Polymer Supplier',
  'Polynesian Restaurant',
  'Polytechnic',
  'Polythene and Plastic Sheeting Supplier',
  'Pond Contractor',
  'Pond Fish Supplier',
  'Pond Supply Store',
  'Pontiac Dealer',
  'Pony Club',
  'Pony Ride Service',
  'Pool & Billiards',
  'Pool Academy',
  'Pool Billard Club',
  'Pool Cleaning Service',
  'Pool Hall',
  'Popcorn Store',
  'Porridge Restaurant',
  'Porsche Dealer',
  'Port Authority',
  'Port Operating Company',
  'Port',
  'Portable Building Manufacturer',
  'Portable Building Service',
  'Portable Toilet Rentals',
  'Portable Toilet Supplier',
  'Portrait Studio',
  'Portuguese Restaurant',
  'Post Office',
  'Poster Store',
  'Pottery Classes',
  'Pottery Manufacturer',
  'Pottery Store',
  'Poultry Farm',
  'Poultry Store',
  'Poutine Place',
  'Powder Coating Service',
  'Power Plant Consultant',
  'Power Plant Equipment Supplier',
  'Power Station',
  'Po\' Boys Restaurant',
  'Prawn Fishing',
  'Pre Gymnasium School',
  'Precision Engineer',
  'Prefabricated House Companies',
  'Prefecture',
  'Pregnancy & Childbirth Service',
  'Pregnancy Care Center',
  'Prenatal/Perinatal Care',
  'Preparatory School',
  'Presbyterian Church',
  'Preschool',
  'Press Advisory',
  'Pressure Washing Service',
  'Pretzel Store',
  'Preventive Medicine',
  'Priest',
  'Primary School',
  'Print Shop',
  'Printed Music Publisher',
  'Printer Ink Refill Store',
  'Printer Repair Service',
  'Printing Equipment Supplier',
  'Printing Equipment and Supplies',
  'Printing Service',
  'Prison & Correctional Facility',
  'Prison',
  'Private College',
  'Private Equity Firm',
  'Private Equity',
  'Private Golf Course',
  'Private Hospital',
  'Private Investigator',
  'Private Plane Charter',
  'Private School',
  'Private Sector Bank',
  'Private Transportation',
  'Private Tutor',
  'Private University',
  'Probation Office',
  'Process Server',
  'Proctologist',
  'Produce Market',
  'Produce Wholesaler',
  'Producteur Eau Minerale',
  'Producteur de Champagne',
  'Producteur de Foie Gras',
  'Professional Organizer',
  'Professional Training',
  'Professional and Hobby Associations',
  'Promenade',
  'Promotional Item Service',
  'Promotional Products Supplier',
  'Propane Supplier',
  'Propeller Shop',
  'Property Administrator',
  'Property Investment',
  'Property Law',
  'Property Maintenance',
  'Property Management Company',
  'Property Management',
  'Prosthetic',
  'Prosthetics',
  'Prosthodontist',
  'Protective Clothing Supplier',
  'Protestant Church',
  'Provence Restaurant',
  'Provincial Council',
  'Psychiatric Hospital',
  'Psychiatrist',
  'Psychic',
  'Psychoanalyst',
  'Psychologist',
  'Psychoneurological Specialized Clinic',
  'Psychopedagogy Clinic',
  'Psychosomatic Medical Practitioner',
  'Psychotherapist',
  'Pub',
  'Public Amenity House',
  'Public Bath',
  'Public Bathroom',
  'Public Call Office Booth',
  'Public Defender\'s Office',
  'Public Female Bathroom',
  'Public Golf Course',
  'Public Health Department',
  'Public Housing',
  'Public Library',
  'Public Male Bathroom',
  'Public Medical Center',
  'Public Parking Space',
  'Public Places & Attractions',
  'Public Prosecutors Office',
  'Public Relations Firm',
  'Public Relations',
  'Public Safety Office',
  'Public Sauna',
  'Public School',
  'Public Sector Bank',
  'Public Service',
  'Public Swimming Pool',
  'Public Transportation',
  'Public University',
  'Public Utility',
  'Public Webcam',
  'Public Wheelchair-Accessible Bathroom',
  'Public Works Department',
  'Publisher',
  'Puerto Rican Restaurant',
  'Pulmonologist',
  'Pump Supplier',
  'Pumping Equipment and Service',
  'Pumpkin Patch',
  'Punjabi Restaurant',
  'Puppet Theater',
  'Qing Fang Market Place',
  'Quad Rental Service',
  'Quaker Church',
  'Quantity Surveyor',
  'Quarry',
  'Quilt Shop',
  'Québécois Restaurant',
  'RV Dealer',
  'RV Dealership',
  'RV Park',
  'RV Repair Shop',
  'RV Repair',
  'RV Storage Facility',
  'RV Supply Store',
  'Race Car Dealer',
  'Race Cars',
  'Race Track',
  'Racecourse',
  'Racing Car Parts Store',
  'Raclette Restaurant',
  'Racquetball Club',
  'Racquetball Court',
  'Radiator Repair Service',
  'Radiator Shop',
  'Radio & Communication Equipment',
  'Radio Broadcaster',
  'Radiologist',
  'Raft Trip Outfitter',
  'Rafting',
  'Rail Museum',
  'Railing Contractor',
  'Railroad Company',
  'Railroad Contractor',
  'Railroad Equipment Supplier',
  'Railroad Ties Supplier',
  'Railroad',
  'Railway Services',
  'Rainwater Tank Supplier',
  'Ramen Restaurant',
  'Ranch',
  'Rare Book Store',
  'Raw Food Restaurant',
  'Ready Mix Concrete Supplier',
  'Real Estate Agency',
  'Real Estate Agent',
  'Real Estate Agents',
  'Real Estate Appraiser',
  'Real Estate Attorney',
  'Real Estate Auctioneer',
  'Real Estate Consultant',
  'Real Estate Developer',
  'Real Estate Fair',
  'Real Estate Investment',
  'Real Estate Lawyer',
  'Real Estate Rental Agency',
  'Real Estate School',
  'Real Estate Service',
  'Real Estate Surveyor',
  'Real Estate Title & Development',
  'Real Estate',
  'Reclamation Centre',
  'Record Company',
  'Record Store',
  'Recording Studio',
  'Records Storage Facility',
  'Recreation Center',
  'Recreational Vehicle Dealership',
  'Recreational Vehicle Rental Agency',
  'Recruiter',
  'Rectory',
  'Recycling & Waste Management',
  'Recycling Center',
  'Reenactment Site',
  'Reflexologist',
  'Reflexology',
  'Reform Synagogue',
  'Reformed Church',
  'Refrigerated Transport Service',
  'Refrigeration Sales & Service',
  'Refrigeration',
  'Refrigerator Repair Service',
  'Refrigerator Store',
  'Refugee Camp',
  'Regional Airport',
  'Regional Council',
  'Regional Government Office',
  'Registered General Nurse',
  'Registration Chamber',
  'Registration Office',
  'Registry Office',
  'Rehabilitation Center',
  'Reiki Therapist',
  'Religious Book Store',
  'Religious Center',
  'Religious Destination',
  'Religious Goods Store',
  'Religious Institution',
  'Religious Organization',
  'Religious School',
  'Religious Seminary',
  'Remodeler',
  'Renault dealer',
  'Rent to Own Store',
  'Rental Company',
  'Rental Shop',
  'Renter\'s Insurance Agency',
  'Repair Service',
  'Reproductive Health Clinic',
  'Reptile Store',
  'Research Engineer',
  'Research Foundation',
  'Research Institute',
  'Research Service',
  'Research and Product Development',
  'Resident Registration Office',
  'Residential College',
  'Residential Real Estate Agent',
  'Residential Real Estate Broker',
  'Residents Association',
  'Resort',
  'Rest Stop',
  'Restaurant Supply Store',
  'Restaurant Supply',
  'Restaurant Wholesale',
  'Restaurant',
  'Resume Service',
  'Retail Space Rental Agency',
  'Retaining Wall Supplier',
  'Retirement & Assisted Living Facility',
  'Retirement Community',
  'Retirement Home',
  'Retreat Center',
  'Rheumatologist',
  'Rice Cracker Shop',
  'Rice Mill',
  'Rice Restaurant',
  'Rice Shop',
  'Rice Wholesaler',
  'River Port',
  'Road Construction Company',
  'Road Construction Machine Repair Service',
  'Road Cycling',
  'Road Safety Town',
  'Roads Ports and Canals Engineers Association',
  'Robotics',
  'Rock Climbing Gym',
  'Rock Climbing Instructor',
  'Rock Climbing',
  'Rock Landscaping Contractor',
  'Rock Music Club',
  'Rock Shop',
  'Rodeo',
  'Rolled Metal Products Supplier',
  'Roller Coaster',
  'Roller Skating Club',
  'Roller Skating Rink',
  'Roman Restaurant',
  'Romanian Restaurant',
  'Roofer',
  'Roofing Contractor',
  'Roofing Supply Store',
  'Roommate Referral Service',
  'Rowing Area',
  'Rowing Club',
  'Rsl Club',
  'Rubber Products Supplier',
  'Rubber Service & Supply',
  'Rubber Stamp Store',
  'Rug Store',
  'Rugby Club',
  'Rugby Field',
  'Rugby League Club',
  'Rugby Store',
  'Rugby',
  'Running Store',
  'Russian Orthodox Church',
  'Russian Restaurant',
  'Rustic Furniture Store',
  'Ryotei Restaurant',
  'SCUBA Instructor',
  'SCUBA Tour Agency',
  'SHOPPING AND RETAIL',
  'Saab Dealer',
  'Sacem',
  'Saddlery',
  'Safe & Vault Shop',
  'Safety & First Aid Service',
  'Safety Equipment Supplier',
  'Sailing Club',
  'Sailing Event Area',
  'Sailing School',
  'Sailmaker',
  'Sake Bar',
  'Sake Brewery',
  'Salad Bar',
  'Salad Place',
  'Salad Shop',
  'Salsa Bar',
  'Salsa Classes',
  'Salvadoran Restaurant',
  'Salvage Dealer',
  'Salvage Yard',
  'Salvation Army',
  'Samba School',
  'Sambo School',
  'Sambodrome',
  'Sand & Gravel Supplier',
  'Sand Plant',
  'Sandblasting Service',
  'Sandwich Shop',
  'Sanitary Inspection',
  'Sanitation Service',
  'Satay Restaurant',
  'Satellite Communication Service',
  'Saturn Dealer',
  'Sauna Club',
  'Sauna Store',
  'Sauna',
  'Savings Bank',
  'Saw Mill',
  'Saw Sharpening Service',
  'Scaffolder',
  'Scaffolding Rental Service',
  'Scale Model Club',
  'Scale Repair Service',
  'Scale Supplier',
  'Scandinavian Restaurant',
  'Scenic Spot',
  'School Administrator',
  'School Bus Service',
  'School Center',
  'School District Office',
  'School For The Deaf',
  'School Fundraiser',
  'School House',
  'School Lunch Center',
  'School Supply Store',
  'School Transportation',
  'School Youth Hostel',
  'School',
  'Science Academy',
  'Science Museum',
  'Scientific Equipment Supplier',
  'Scooter Rental Service',
  'Scooter Rental',
  'Scooter Repair Shop',
  'Scout Hall',
  'Scout Home',
  'Scouting',
  'Scrap Metal Dealer',
  'Scrapbooking Store',
  'Screen Printer',
  'Screen Printing & Embroidery',
  'Screen Printing Shop',
  'Screen Printing Supply Store',
  'Screen Repair Service',
  'Screen Store',
  'Screw Supplier',
  'Scuba Diving',
  'Sculptor',
  'Sculpture Museum',
  'Sculpture',
  'Seafood Donburi Restaurant',
  'Seafood Farm',
  'Seafood Market',
  'Seafood Restaurant',
  'Seafood Wholesaler',
  'Seal Shop',
  'Seaplane Base',
  'Seasonal Goods Store',
  'Seasonal Store',
  'Second Hand Store',
  'Secondary School Three',
  'Secretarial Service',
  'Security Guard Service',
  'Security Service',
  'Security System Installer',
  'Security System Supplier',
  'Security',
  'Seed Supplier',
  'Seitai',
  'Self Defense School',
  'Self Service Car Wash',
  'Self Service Restaurant',
  'Self-Catering Accommodation',
  'Self-Storage Facility',
  'Semi Conductor Supplier',
  'Seminary',
  'Senior Center',
  'Senior Citizen Center',
  'Senior High School',
  'Septic System Service',
  'Septic Tank Service',
  'Serbian Restaurant',
  'Service Station Supply',
  'Serviced Accommodation',
  'Seventh Day Adventist Church',
  'Seventh-day Adventist Church',
  'Sewage Disposal Service',
  'Sewage Treatment Plant',
  'Sewer Service',
  'Sewing & Seamstress',
  'Sewing Company',
  'Sewing Machine Repair Service',
  'Sewing Machine Store',
  'Sewing Shop',
  'Seychelles Restaurant',
  'Shabu-shabu Restaurant',
  'Shan Dong Restaurant',
  'Shanghai Restaurant',
  'Shanghainese Restaurant',
  'Sharpening Service',
  'Shed Builder',
  'Sheep Shearer',
  'Sheepskin Coat Store',
  'Sheepskin and Wool Products Supplier',
  'Sheet Metal Contractor',
  'Sheet Music Store',
  'Shelter',
  'Sheltered Housing',
  'Shelving Store',
  'Sheriff\'s Department',
  'Shinkin Bank',
  'Shinto Shrine',
  'Ship Building',
  'Shipbuilding and Repair Company',
  'Shipping Company',
  'Shipping Equipment Industry',
  'Shipping Service',
  'Shipping and Mailing Service',
  'Shipyard',
  'Shochu Brewery',
  'Shoe Factory',
  'Shoe Repair Shop',
  'Shoe Shining Service',
  'Shoe Store',
  'Shogi Lesson',
  'Shooting Event Area',
  'Shooting Range',
  'Shop Supermarket Furniture Store',
  'Shopfitter',
  'Shopping District',
  'Shopping Mall',
  'Shopping Service',
  'Short Term Apartment Rental Agency',
  'Shower Door Shop',
  'Shower Door Supplier',
  'Shredding Service',
  'Shrimp Farm',
  'Shrine',
  'Sichuan Restaurant',
  'Sicilian Restaurant',
  'Siding Contractor',
  'Sightseeing Tour Agency',
  'Sign Shop',
  'Signs & Banner Service',
  'Sikh Temple',
  'Silk Plant Shop',
  'Silk Store',
  'Silversmith',
  'Singaporean Restaurant',
  'Singing Telegram Service',
  'Single Sex Secondary School',
  'Singles Organization',
  'Sixth Form College',
  'Skate Sharpening Service',
  'Skate Shop',
  'Skateboard Park',
  'Skateboard Shop',
  'Skating Instructor',
  'Skeet Shooting Range',
  'Ski & Snowboard School',
  'Ski Club',
  'Ski Rental Service',
  'Ski Repair Service',
  'Ski Resort',
  'Ski School',
  'Ski Shop',
  'Skin Care Clinic',
  'Skin Care Products Vending Machine',
  'Skin Care',
  'Skittle Alley',
  'Skittle Club',
  'Sky Diving',
  'Skydiving Center',
  'Skylight Contractor',
  'Slaughterhouse',
  'Sleep Clinic',
  'Sleep Specialist',
  'Small Appliance Repair Service',
  'Small Claims Assistance Service',
  'Small Engine Repair Service',
  'Small Plates Restaurant',
  'Smart Shop',
  'Smog Inspection Station',
  'Smoothie & Juice Bar',
  'Snack Bar',
  'Snow Removal Service',
  'Snowboard Rental Service',
  'Snowboard Shop',
  'Snowmobile Dealer',
  'Snowmobile Rental Service',
  'Snowmobiles',
  'Soapland',
  'Soba Noodle Shop',
  'Soba Restaurant',
  'Soccer Club',
  'Soccer Field',
  'Soccer Practice',
  'Soccer Store',
  'Social Club',
  'Social Security Attorney',
  'Social Security Financial Department',
  'Social Security Law',
  'Social Security Office',
  'Social Services Organization',
  'Social Services',
  'Social Welfare Center',
  'Social Worker',
  'Societe de Flocage',
  'Sod Supplier',
  'Sofa Store',
  'Soft Drinks Shop',
  'Softball Club',
  'Softball Field',
  'Software Company',
  'Software Consulting',
  'Software Development',
  'Software Training Institute',
  'Soil Testing Service',
  'Sokol House',
  'Solar Energy Company',
  'Solar Energy Contractor',
  'Solar Energy Equipment Supplier',
  'Solar Energy Service',
  'Solar Hot Water System Supplier',
  'Solar Photovoltaic Power Plant',
  'Solid Fuel Company',
  'Solid Waste Engineer',
  'Sorority & Fraternity',
  'Soul Food Restaurant',
  'Soup Kitchen',
  'Soup Restaurant',
  'Soup Shop',
  'South African Restaurant',
  'South American Restaurant',
  'South Asian Restaurant',
  'Southeast Asian Restaurant',
  'Southern Italian Restaurant',
  'Southern Restaurant (US)',
  'Southern Restaurant',
  'Southwest France Restaurant',
  'Southwestern Restaurant (US)',
  'Southwestern Restaurant',
  'Souvenir Manufacturer',
  'Souvenir Store',
  'Souvlaki Shop',
  'Soy Sauce Maker',
  'Spa Garden',
  'Spa Resort',
  'Spa Town',
  'Spa and Health Club',
  'Spa',
  'Spanish Language School',
  'Spanish Restaurant',
  'Speakeasy',
  'Special Education School',
  'Specialist Hospital',
  'Specialized Clinic',
  'Specialized hospital',
  'Specialty Grocery Store',
  'Specialty School',
  'Speech Pathologist',
  'Speech Therapist',
  'Sperm Bank',
  'Spice Store',
  'Spices Exporter',
  'Spices Wholesalers',
  'Spine Surgeon',
  'Spiritist Center',
  'Sport Tour Agency',
  'Sporting Goods Store',
  'Sports & Recreation',
  'Sports Accessories Wholesaler',
  'Sports Bar',
  'Sports Card Store',
  'Sports Club',
  'Sports Complex',
  'Sports Equipment Rental Service',
  'Sports Instruction',
  'Sports Law',
  'Sports Massage Therapist',
  'Sports Medicine Clinic',
  'Sports Medicine Physician',
  'Sports Medicine',
  'Sports Memorabilia Store',
  'Sports Nutrition Store',
  'Sports Promoter',
  'Sports School',
  'Sportswear Store',
  'Sportswear',
  'Sportwear Manufacturer',
  'Spring Supplier',
  'Squash Club',
  'Squash Court',
  'Sri Lankan Restaurant',
  'Stable',
  'Stadium',
  'Stage Lighting Equipment Supplier',
  'Stage',
  'Stained Glass Studio',
  'Stainless Steel Plant',
  'Stair Contractor',
  'Stall Installation Service',
  'Stamp Collectors Club',
  'Stamp Shop',
  'Stand Bar',
  'Staple Food Package',
  'Startup',
  'State Archive',
  'State Department Agricultural Development',
  'State Department Agriculture Food Supply',
  'State Department Civil Defense',
  'State Department Communication',
  'State Department Finance',
  'State Department Housing and Urban Development',
  'State Department Science Technology',
  'State Department Social Defense',
  'State Department for Social Development',
  'State Department of Environment',
  'State Department of Tourism',
  'State Department of Transportation',
  'State Dept of Culture',
  'State Dept of Sports',
  'State Employment Department',
  'State Government Office',
  'State Liquor Store',
  'State Office of Education',
  'State Owned Farm',
  'State Park',
  'State Police',
  'State Social Development',
  'State',
  'Stationery Manufacturer',
  'Stationery Store',
  'Stationery Wholesaler',
  'Statuary',
  'Steak House',
  'Steakhouse',
  'Steamboat Restaurant',
  'Steamed Bun Shop',
  'Steel Construction Company',
  'Steel Distributor',
  'Steel Drum Supplier',
  'Steel Erector',
  'Steel Fabricator',
  'Steel Framework Contractor',
  'Steelwork Design Company',
  'Steelwork Manufacturer',
  'Stereo Rental Store',
  'Stereo Repair Service',
  'Sticker Manufacturer',
  'Stitching Class',
  'Stock Broker',
  'Stock Exchange Building',
  'Stone Carving',
  'Stone Cutter',
  'Stone Supplier',
  'Storage Facility',
  'Storage Service',
  'Storage',
  'Store Equipment Supplier',
  'Store',
  'Stove Builder',
  'Stringed instrument maker',
  'Structural Engineer',
  'Stucco Contractor',
  'Student Career Counseling Office',
  'Student Dormitory',
  'Student Housing Center',
  'Student Union',
  'Students Parents Association',
  'Students Support Association',
  'Study at Home School',
  'Studying Center',
  'Stylist',
  'Subaru Dealer',
  'Suburban Train Line',
  'Subway & Light Rail Station',
  'Sugar Factory',
  'Sugar Shack',
  'Sukiyaki Restaurant',
  'Sukiyaki and Shabu Shabu Restaurant',
  'Summer Camp',
  'Summer Toboggan Run',
  'Sundae Restaurant',
  'Sunglasses Store',
  'Sunroom Contractor',
  'Super Public Bath',
  'Superannuation Consultant',
  'Superfund Site',
  'Supermarket',
  'Supply & Distribution Service',
  'Suppon Restaurant',
  'Surf Lifesaving Club',
  'Surf School',
  'Surf Shop',
  'Surfing Spot',
  'Surgeon',
  'Surgical Center',
  'Surgical Products Wholesaler',
  'Surgical Supply Store',
  'Surinamese Restaurant',
  'Surplus Store',
  'Surveyor',
  'Sushi Restaurant',
  'Suzuki Dealer',
  'Suzuki Motorcycle Dealer',
  'Swedish Restaurant',
  'Sweets and Dessert Buffet',
  'Swim Club',
  'Swimming Basin',
  'Swimming Competition',
  'Swimming Facility',
  'Swimming Instructor',
  'Swimming Lake',
  'Swimming Pool Contractor',
  'Swimming Pool Maintenance',
  'Swimming Pool Repair Service',
  'Swimming Pool Supply Store',
  'Swimming Pool',
  'Swimming School',
  'Swimwear Store',
  'Swimwear',
  'Swiss Restaurant',
  'Symphony',
  'Synagogue',
  'Synchronized Swimming',
  'Syokudo and Teishoku Restaurant',
  'Syrian Restaurant',
  'Szechuan Restaurant',
  'T-shirt Company',
  'T-shirt Store',
  'TECHNOLOGY AND STARTUPS',
  'TRAVEL AND TRANSPORTATION',
  'Table & Chair Rental Service',
  'Table Tennis Club',
  'Table Tennis Facility',
  'Table Tennis Supply Store',
  'Tack Shop',
  'Taco Place',
  'Taco Restaurant',
  'Tae Kwon Do Comp Area',
  'Taekwondo School',
  'Tag Agency',
  'Tai Chi School',
  'Tailor',
  'Taiwanese Restaurant',
  'Take Out Restaurant',
  'Takoyaki Restaurant',
  'Talent Agency',
  'Talent Management',
  'Tamale Shop',
  'Tannery',
  'Tanning Salon Supplier',
  'Tanning Salon',
  'Taoist Temple',
  'Tapas Bar & Restaurant',
  'Tapas Bar',
  'Tapas Restaurant',
  'Tatami Store',
  'Tattoo & Piercing',
  'Tattoo Removal Service',
  'Tattoo Removal',
  'Tattoo Shop',
  'Tattoo and Piercing Shop',
  'Tax Assessor',
  'Tax Attorney',
  'Tax Collector\'s Office',
  'Tax Consultant',
  'Tax Department',
  'Tax Law',
  'Tax Preparation Service',
  'Tax Preparation',
  'Taxi Service',
  'Taxi Stand',
  'Taxi',
  'Taxidermist',
  'Tb Clinic',
  'Tea Exporter',
  'Tea House',
  'Tea Manufacturer',
  'Tea Market Place',
  'Tea Room',
  'Tea Store',
  'Tea Wholesaler',
  'Teacher College',
  'Technical Institute',
  'Technical School',
  'Technical Service',
  'Technical University',
  'Technology Consultant',
  'Technology Museum',
  'Technology Park',
  'Teeth Whitening Service',
  'Teeth Whitening',
  'Telecommunication School',
  'Telecommunications Contractor',
  'Telecommunications Engineer',
  'Telecommunications Equipment Supplier',
  'Telecommunications Service Provider',
  'Telemarketing Service',
  'Telephone Answering Service',
  'Telephone Company',
  'Telephone Exchange',
  'Telescope Store',
  'Television Repair Service',
  'Television Station',
  'Temp Agency',
  'Tempura Donburi Restaurant',
  'Tempura Restaurant',
  'Tenant Ownership',
  'Tenant and Eviction Law',
  'Tenant\'s Union',
  'Tennis Club',
  'Tennis Court Construction Company',
  'Tennis Court',
  'Tennis Instructor',
  'Tennis Store',
  'Tennis',
  'Tent Rental Service',
  'Teppanyaki Restaurant',
  'Tex-Mex Restaurant',
  'Textile Engineer',
  'Textile Exporter',
  'Textile Mill',
  'Textiles',
  'Thai Massage Therapist',
  'Thai Restaurant',
  'Theater Company',
  'Theater Production',
  'Theater Supply Store',
  'Theater',
  'Theatrical Costume Supplier',
  'Theme Park',
  'Theme Restaurant',
  'Thermal Baths',
  'Thermal Energy Company',
  'Thread Supplier',
  'Threads and Yarns Wholesaler',
  'Thrift Store',
  'Thrift or Consignment Store',
  'Tibetan Restaurant',
  'Ticket Sales',
  'Tiki Bar',
  'Tile Contractor',
  'Tile Manufacturer',
  'Tile Store',
  'Time and Temperature Announcement Service',
  'Timeshare Agency',
  'Tire Dealer & Repair Shop',
  'Tire Shop',
  'Title Company',
  'Tobacco Exporter',
  'Tobacco Shop',
  'Tobacco Store',
  'Tobacco Supplier',
  'Tofu Restaurant',
  'Tofu Shop',
  'Toiletries Store',
  'Toll Booth',
  'Toll Road Rest Stop',
  'Toner Cartridge Supplier',
  'Tongue Restaurant',
  'Tonkatsu Restaurant',
  'Tool & Die Shop',
  'Tool Grinding Service',
  'Tool Manufacturer',
  'Tool Rental Service',
  'Tool Repair Shop',
  'Tool Store',
  'Tool Wholesaler',
  'Toolroom',
  'Tools Service',
  'Topography Company',
  'Topsoil Supplier',
  'Tour Agency',
  'Tour Company',
  'Tour Guide',
  'Tour Operator',
  'Tourist Attraction',
  'Tourist Information Center',
  'Tourist Information',
  'Tours & Sightseeing',
  'Tower Communication Service',
  'Towing Equipment Provider',
  'Towing Service',
  'Townhouse Complex',
  'Toxicologist',
  'Toy Library',
  'Toy Manufacturer',
  'Toy Museum',
  'Toy Store',
  'Toy and Game Manufacturer',
  'Toyota Dealer',
  'Tractor Dealer',
  'Tractor Equipment Supplier',
  'Tractor Repair Shop',
  'Trade Fair Construction Company',
  'Trade School',
  'Trading Card Store',
  'Traditional American Restaurant',
  'Traditional Chinese Medicine',
  'Traditional Costume Club',
  'Traditional Kostume Store',
  'Traditional Market',
  'Traditional Restaurant',
  'Traditional Teahouse',
  'Traffic Officer',
  'Traffic Police Station',
  'Traffic School',
  'Traffic Ticketing Law',
  'Trailer Dealer',
  'Trailer Hitch Supplier',
  'Trailer Manufacturer',
  'Trailer Rental Service',
  'Trailer Rental',
  'Trailer Repair Shop',
  'Trailer Supply Store',
  'Train Repairing Center',
  'Train Station',
  'Train Ticket Agency',
  'Train Ticket Counter',
  'Train yard',
  'Training Centre',
  'Training School',
  'Transcription Service',
  'Translator',
  'Transmission Shop',
  'Transportation Escort Service',
  'Transportation Service',
  'Travel Agency',
  'Travel Clinic',
  'Travellers Lodge',
  'Tree Farm',
  'Tree Service',
  'Trial Attorney',
  'Tribal Headquarters',
  'Trinity Church',
  'Triumph Motorcycle Dealer',
  'Trophies & Engraving',
  'Trophy Shop',
  'Tropical Fish Store',
  'Truck Accessories Store',
  'Truck Dealer',
  'Truck Farmer',
  'Truck Parts Supplier',
  'Truck Rental Agency',
  'Truck Rental',
  'Truck Repair Shop',
  'Truck Stop',
  'Truck Topper Supplier',
  'Truck Towing',
  'Truck Wash',
  'Trucking Company',
  'Trucking School',
  'Truss Manufacturer',
  'Trust Bank',
  'Tsukigime Parking Lot',
  'Tune Up Supplier',
  'Tuning Automobile',
  'Tunisian Restaurant',
  'Turf Supplier',
  'Turkish Restaurant',
  'Turkmen Restaurant',
  'Turnery',
  'Tuscan Restaurant',
  'Tutoring Service',
  'Tutoring',
  'Tuxedo Shop',
  'Typewriter Repair Service',
  'Typewriter Supplier',
  'Typing Service',
  'Tyre Manufacturer',
  'UX Design',
  'Udon Noodle Restaurant',
  'Udon Restaurant',
  'Ukrainian Restaurant',
  'Ukranian Restaurant',
  'Unagi Restaurant',
  'Undersea/Hyperbaric Medicine',
  'Underwear Store',
  'Unemployment Insurance Consultant',
  'Unemployment Office',
  'Unfinished Furniture Store',
  'Uniform Store',
  'Unitarian Universalist Church',
  'United Church of Canada',
  'United Church of Christ',
  'United Methodist Church',
  'United States Armed Forces Base',
  'Unity Church',
  'University Department',
  'University Hospital',
  'University Library',
  'University',
  'Upholstery Cleaning Service',
  'Upholstery Service',
  'Upholstery Shop',
  'Urban Farm',
  'Urban Planning Department',
  'Urgent care center',
  'Urologist',
  'Urology Clinic',
  'Uruguayan Restaurant',
  'Used Appliance Store',
  'Used Auto Parts Store',
  'Used Bicycle Shop',
  'Used Book Store',
  'Used CD Store',
  'Used Car Dealer',
  'Used Clothing Store',
  'Used Computer Store',
  'Used Furniture Store',
  'Used Game Store',
  'Used Motorcycle Dealer',
  'Used Musical Instrument Store',
  'Used Office Furniture Store',
  'Used Store Fixture Supplier',
  'Used Tire Shop',
  'Used Truck Dealer',
  'Utility Contractor',
  'Utility Trailer Dealer',
  'Uzbeki Restaurant',
  'VCR Repair Service',
  'Vacation Home Rental Agency',
  'Vacation Home Rental',
  'Vacuum Cleaner Repair Shop',
  'Vacuum Cleaner Store',
  'Vacuum Cleaning System Supplier',
  'Valencian Restaurant',
  'Valet Parking Service',
  'Van Rental Agency',
  'Vaporizer Store',
  'Variety Store',
  'Vascular Medicine',
  'Vascular Surgeon',
  'Vastu Consultant',
  'Vegan Restaurant',
  'Vegetable Wholesale Market',
  'Vegetable Wholesaler',
  'Vegetarian & Vegan Restaurant',
  'Vegetarian Cafe and Deli',
  'Vegetarian Restaurant',
  'Vehicle Examination Office',
  'Vehicle Exporter',
  'Vehicle Inspection',
  'Vehicle Shipping Agent',
  'Velodrome',
  'Vending Machine Service',
  'Vending Machine Supplier',
  'Venereologist',
  'Venetian Restaurant',
  'Venezuelan Restaurant',
  'Ventilating Equipment Manufacturer',
  'Venture Capital Company',
  'Venture Capital',
  'Veterans Affairs Department',
  'Veterans Center',
  'Veterans Hospital',
  'Veterans Organization',
  'Veterinarian',
  'Veterinary Pharmacy',
  'Video Arcade',
  'Video Camera Repair Service',
  'Video Conferencing Equipment Supplier',
  'Video Conferencing Service',
  'Video Duplication Service',
  'Video Editing Service',
  'Video Equipment Repair Service',
  'Video Game Rental Kiosk',
  'Video Game Rental Service',
  'Video Game Rental Store',
  'Video Game Store',
  'Video Game',
  'Video Karaoke',
  'Video Production Service',
  'Video Services',
  'Video Store',
  'Vietnamese Restaurant',
  'Villa',
  'Village Hall',
  'Vineyard Church',
  'Vineyard',
  'Vintage Clothing Store',
  'Vintage Store',
  'Violin Shop',
  'Virtual Office Rental',
  'Visa Consultant',
  'Visa and Passport Office',
  'Visitor Center',
  'Vitamin & Supplements Store',
  'Vocal Instructor',
  'Vocational College',
  'Vocational School One',
  'Vocational School',
  'Volkswagen Dealer',
  'Volleyball Club',
  'Volleyball Court',
  'Volleyball Instructor',
  'Volunteer Organization',
  'Volvo Dealer',
  'Voter Registration Office',
  'Voting Facility',
  'Waldorf Kindergarten',
  'Waldorf School',
  'Walk-In Clinic',
  'Wallpaper Store',
  'Wallpaper',
  'War Museum',
  'Warehouse club',
  'Warehouse store',
  'Warehouse',
  'Washer & Dryer Repair Service',
  'Washer & Dryer Store',
  'Waste Management Service',
  'Waste Management',
  'Watch Manufacturer',
  'Watch Repair Service',
  'Watch Store',
  'Water Cooler Supplier',
  'Water Damage Restoration Service',
  'Water Filter Supplier',
  'Water Filtration & Treatment',
  'Water Jet Cutting Service',
  'Water Mill',
  'Water Park',
  'Water Polo Pool',
  'Water Pump Supplier',
  'Water Purification Company',
  'Water Ski Shop',
  'Water Skiing Club',
  'Water Skiing Instructor',
  'Water Skiing Service',
  'Water Softening Equipment Supplier',
  'Water Sports Equipment Rental Service',
  'Water Tank Cleaning Service',
  'Water Testing Service',
  'Water Treatment Plant',
  'Water Treatment Supplier',
  'Water Utility Company',
  'Water Works Equipment Supplier',
  'Water Works',
  'Waterbed Repair Service',
  'Waterbed Store',
  'Waterproofing Company',
  'Wax Museum',
  'Wax Supplier',
  'Waxing Hair Removal Service',
  'Weather Forecast Service',
  'Weaving Mill',
  'Web Design',
  'Web Hosting Company',
  'Website Designer',
  'Wedding Bakery',
  'Wedding Buffet',
  'Wedding Chapel',
  'Wedding Dress Rental Service',
  'Wedding Hall',
  'Wedding Photographer',
  'Wedding Planner',
  'Wedding Planning',
  'Wedding Service',
  'Wedding Souvenir Shop',
  'Wedding Store',
  'Wedding Venue',
  'Weigh Station',
  'Weight Loss Center',
  'Weight Loss Service',
  'Weightlifting Area',
  'Weir',
  'Welder',
  'Welding Gas Supplier',
  'Welding Supply Store',
  'Well Drilling Contractor',
  'Well Water Drilling Service',
  'Wellness Center',
  'Wellness Hotel',
  'Wellness Program',
  'Welsh Restaurant',
  'Wesleyan Church',
  'West African Restaurant',
  'Western Apparel Store',
  'Western Restaurant',
  'Whale Watching Tour Agency',
  'Wheel Alignment Service',
  'Wheel Store',
  'Wheelchair Rental Service',
  'Wheelchair Repair Service',
  'Wheelchair Store',
  'Wholesale & Supply Store',
  'Wholesale Bakery',
  'Wholesale Drugstore',
  'Wholesale Florist',
  'Wholesale Food Store',
  'Wholesale Grocer',
  'Wholesale Jeweler',
  'Wholesale Market',
  'Wholesale Plant Nursery',
  'Wholesaler Household Appliances',
  'Wholesaler',
  'Wi-Fi Spot',
  'Wicker Store',
  'Wig Shop',
  'Wig Store',
  'Wildlife Park',
  'Wildlife Refuge',
  'Wildlife Rescue Service',
  'Wildlife Sanctuary',
  'Wildlife and Safari Park',
  'Willow Basket Manufacturer',
  'Wills & Estate Lawyer',
  'Wind Farm',
  'Wind Turbine Builder',
  'Window Cleaning Service',
  'Window Installation Service',
  'Window Service & Repair',
  'Window Supplier',
  'Window Tinting Service',
  'Window Treatment Store',
  'Windsurfing Store',
  'Wine Bar',
  'Wine Cellar',
  'Wine Club',
  'Wine Storage Facility',
  'Wine Store',
  'Wine Wholesaler and Importer',
  'Winemaking Supply Store',
  'Winery',
  'Winery/Vineyard',
  'Wing Chun School',
  'Wok Restaurant',
  'Women\'s Health',
  'Womens College',
  'Womens Personal Trainer',
  'Womens Protection Service',
  'Women\'s Clothing Store',
  'Women\'s Health Clinic',
  'Women\'s Organization',
  'Women\'s Shelter',
  'Wood Floor Installation Service',
  'Wood Floor Refinishing Service',
  'Wood Frame Supplier',
  'Wood Stove Shop',
  'Wood Supplier',
  'Wood Working Class',
  'Wood and Laminate Flooring Supplier',
  'Woodworker',
  'Woodworking Supply Store',
  'Wool Store',
  'Work Clothes Store',
  'Workers Compensation Law',
  'Workers\' Club',
  'Working Womens Hostel',
  'Workplace & Office',
  'Wrestling School',
  'Writing Service',
  'X-ray Equipment Supplier',
  'X-ray Lab',
  'Xiang Cuisine Restaurant',
  'Yacht Broker',
  'Yacht Club',
  'Yakatabune',
  'Yakiniku Restaurant',
  'Yakitori Restaurant',
  'Yamaha Motorcycle Dealer',
  'Yarn Store',
  'Yemenite Restaurant',
  'Yeshiva',
  'Yoga & Pilates',
  'Yoga Instructor',
  'Yoga Retreat Center',
  'Yoga Studio',
  'Youth Care',
  'Youth Center',
  'Youth Clothing Store',
  'Youth Club',
  'Youth Group',
  'Youth Hostel',
  'Youth Organization',
  'Youth Social Services Organization',
  'Zac',
  'Zhe Jiang Restaurant',
  'Zoo & Aquarium',
  'Zoo',
];

// Arts, Entertainment and Recreation
const ARTS_ENTERTAINMENT_RECREATION = [
  'Amusement',
  'Amusement Park Ride',
  'Arcade',
  'Archery',
  'Art Gallery',
  'Art Museum',
  'Art School',
  'Auditorium',
  'Bar',
  'Bartending Service',
  'Batting Cage',
  'Beach',
  'Beach Bar',
  'Beer Bar',
  'Beer Garden',
  'Bingo Hall',
  'Boating',
  'Bowling Alley',
  'Brewery',
  'Casino',
  'Caterer',
  'Champagne Bar',
  'Cheerleading',
  'Circus',
  'City',
  'Cocktail Bar',
  'Comedy Club',
  'Computer Training',
  'Concert Venue',
  'Cooking Lesson',
  'County',
  'Culinary School',
  'DJ',
  'Dance Instruction',
  'Dating Service',
  'Dive Bar',
  'Driving Range',
  'Driving School',
  'Entertainer',
  'Event Planner',
  'Event Venue',
  'Fairground',
  'Farm',
  'First Aid Class',
  'Fishing',
  'Food Consultant',
  'Go Karting',
  'Golf Course',
  'Gun Range',
  'Gym',
  'History Museum',
  'Horses',
  'Hot Air Balloons',
  'Hotel Bar',
  'Hunting and Fishing',
  'Ice Skating',
  'Irish Pub',
  'Island',
  'Jazz Club',
  'Karaoke',
  'Laser Tag',
  'Lounge',
  'Martial Arts',
  'Miniature Golf',
  'Modern Art Museum',
  'Mountain Biking',
  'Movie Theater',
  'Museum',
  'Music Lessons & Instruction',
  'Musician/Band',
  'National Park',
  'Neighborhood',
  'Newspaper',
  'Night Club',
  'Nightlife',
  'Orchestra',
  'Outdoor Recreation',
  'Outdoors',
  'Paintball',
  'Park',
  'Party Supplies',
  'Performance Venue',
  'Personal Trainer',
  'Petting Zoo',
  'Physical Fitness',
  'Picnic Ground',
  'Playground',
  'Pool & Billiards',
  'Pub',
  'Race Track',
  'Racquetball Court',
  'Rafting',
  'Recreation Center',
  'Rock Climbing',
  'Rodeo',
  'Roller Coaster',
  'Sake Bar',
  'Scuba Diving',
  'Ski & Snowboard School',
  'Ski Resort',
  'Sky Diving',
  'Social Club',
  'Speakeasy',
  'Sports & Recreation',
  'Sports Bar',
  'Sports Club',
  'Sports Instruction',
  'State Park',
  'Surfing Spot',
  'Swimming Pool',
  'Symphony',
  'Tennis',
  'Theater',
  'Ticket Sales',
  'Tiki Bar',
  'Urban Farm',
  'Water Park',
  'Wedding Hall',
  'Wedding Planning',
  'Wildlife Sanctuary',
  'Wine Bar',
  'Winery/Vineyard',
  'Yoga & Pilates',
  'Zoo & Aquarium',
];
// Business Service
const BUSINESS_SERVICE = [
  'Accountant',
  'Advertising Agency',
  'Advertising Service',
  'App Development',
  'Appraiser',
  'Audiovisual Equipment',
  'Bail Bonds',
  'Bank',
  'Bank Equipment & Service',
  'Book & Magazine Distribution',
  'Brokers & Franchising',
  'Business Center',
  'Business Consultant',
  'Business Supply Service',
  'Carnival Supplies',
  'Cash Advance Service',
  'Chemicals',
  'Clothing Supply & Distribution',
  'Collection Agency',
  'Commercial Bank',
  'Commercial Real Estate',
  'Commercial Real Estate Agent',
  'Commercial Real Estate Broker',
  'Computer Service',
  'Copying & Printing',
  'Copywriting Service',
  'Coworking Space',
  'Credit Counseling',
  'Currency Exchange',
  'Dental Equipment',
  'Direct Mail Service',
  'Document Service',
  'Drinking Water Distribution',
  'Educational Consultant',
  'Electronic Equipment Service',
  'Email Marketing',
  'Employment Agency',
  'Energy Consulting',
  'Environmental Consultant',
  'Equipment Service & Supply',
  'Escrow Service',
  'Estate Planning',
  'Executive Search',
  'Financial Advisor',
  'Financial Planning',
  'Financial Service',
  'Food & Beverage Service & Distribution',
  'Franchising Service',
  'Glass Products',
  'Handwriting Service',
  'Hotel Supply Service',
  'IT Services',
  'Image Consultant',
  'Insurance Agent',
  'Insurance Broker',
  'Internet Service Provider',
  'Inventory Control Service',
  'Investing Service',
  'Janitorial Service',
  'Jewelry Supplier',
  'Laboratory Equipment',
  'Loans',
  'Management Service',
  'Marketing Consultant',
  'Meeting Room',
  'Merchandising Service',
  'Mobile Development',
  'Mortgage Brokers',
  'PR Firm',
  'Packaging Supplies & Equipment',
  'Passport & Visa Service',
  'Patrol & Security',
  'Printing Service',
  'Private Equity',
  'Private Investigator',
  'Promotional Item Service',
  'Public Relations',
  'Radio & Communication Equipment',
  'Real Estate',
  'Real Estate Agent',
  'Real Estate Appraiser',
  'Real Estate Developer',
  'Real Estate Investment',
  'Real Estate Service',
  'Real Estate Title & Development',
  'Recruiter',
  'Refrigeration Sales & Service',
  'Repair Service',
  'Research Service',
  'Residential Real Estate Agent',
  'Residential Real Estate Broker',
  'Restaurant Supply',
  'Rubber Service & Supply',
  'Secretarial Service',
  'Security',
  'Service Station Supply',
  'Software Consulting',
  'Software Development',
  'Supply & Distribution Service',
  'Talent Management',
  'Tanning Salon Supplier',
  'Tax Preparation',
  'Technology Consultant',
  'Telemarketing Service',
  'Translator',
  'Venture Capital',
  'Waste Management',
  'Workplace & Office',
  'Writing Service',
];
// Community, Organization and Education
const COMMUNITY_ORG_EDUCATION = [
  'Active Life',
  'Admissions Training',
  'African Methodist Episcopal Church',
  'Anglican Church',
  'Apartment & Condo Building',
  'Apostolic Church',
  'Armed Forces',
  'Art School',
  'Assembly of God',
  'Athletic Education',
  'Aviation School',
  'Baptist Church',
  'Buddhist Temple',
  'Campus Building',
  'Catholic Church',
  'Cemetery',
  'Charismatic Church',
  'Charity Organization',
  'Child Care',
  'Child Protective Service',
  'Christian Church',
  'Christian Science Church',
  'Church',
  'Church of Christ',
  'Church of God',
  'Church of Jesus Christ of Latter-day Saints',
  'City Hall',
  'Civic Structure',
  'Classes',
  'Clergy',
  'Clubhouse',
  'College & University',
  'Community Center',
  'Community Organization',
  'Computer Training',
  'Confucian Temple',
  'Congregational Church',
  'Convent & Monastery',
  'Convention Center',
  'Cooking Lesson',
  'Corporate Office',
  'Courthouse',
  'Coworking Space',
  'Crisis Prevention Center',
  'Culinary School',
  'Dance Instruction',
  'Day Care',
  'Disability Service',
  'Dorm',
  'Driving School',
  'Eastern Orthodox Church',
  'Education',
  'Educational Camp',
  'Educational Consultant',
  'Educational Organization',
  'Educational Research',
  'Educational Service',
  'Educational Supplies',
  'Elementary School',
  'Environmental Conservation',
  'Episcopal Church',
  'Evangelical Church',
  'Exchange Program',
  'Financial Aid',
  'Fire Station',
  'First Aid Class',
  'Full Gospel Church',
  'Government Organization',
  'High School',
  'Highway',
  'Hindu Temple',
  'Historical Place',
  'Holiness Church',
  'Housing & Homeless Shelter',
  'Housing Assistance Service',
  'Independent Church',
  'Interdenominational Church',
  'Junior High School',
  'Kingdom Hall',
  'Landmark',
  'Language School',
  'Law Enforcement',
  'Library',
  'Lobbyist',
  'Local Education',
  'Lutheran Church',
  'Medical School',
  'Meeting Room',
  'Mennonite Church',
  'Methodist Church',
  'Middle School',
  'Military Base',
  'Mission',
  'Monument',
  'Mosque',
  'Music Lessons & Instruction',
  'Music School',
  'Nazarene Church',
  'Neighborhood',
  'Non-Profit',
  'Nondenominational Church',
  'Notary Public',
  'Nursery School',
  'Organization',
  'Pentecostal Church',
  'Police Station',
  'Political Organization',
  'Post Office',
  'Presbyterian Church',
  'Preschool',
  'Prison & Correctional Facility',
  'Private School',
  'Professional Training',
  'Public Places & Attractions',
  'Public School',
  'Public Service',
  'Public Utility',
  'Religious Book Store',
  'Religious Center',
  'Religious Organization',
  'Religious School',
  'Salvation Army',
  'School',
  'School Fundraiser',
  'Senior Center',
  'Seventh Day Adventist Church',
  'Sikh Temple',
  'Ski & Snowboard School',
  'Social Services',
  'Sorority & Fraternity',
  'Specialty School',
  'Sports Instruction',
  'Synagogue',
  'Technical Institute',
  'Trade School',
  'Traffic School',
  'Tutoring',
  'Workplace & Office',
  'Youth Organization',
];
// Creative and Lifestyle Services
const CREATIVE_LIFESTYLE_SERVICES = [
  'Advertising Agency',
  'Architect',
  'Art Restoration',
  'Artistic Service',
  'Bartending Service',
  'Broadcasting & Media Production',
  'Caterer',
  'Content Services',
  'DJ',
  'Dating Service',
  'Digital Design',
  'Entertainer',
  'Entertainment Service',
  'Event Planner',
  'Event Venue',
  'Fashion Designer',
  'Florist',
  'Food Consultant',
  'Graphic Design',
  'Hospitality Service',
  'Image Consultant',
  'Life Coach',
  'Lifestyle Services',
  'Marketing Consultant',
  'Mobile Design',
  'Modeling Agency',
  'Movie & Television Studio',
  'Music Production',
  'Musician/Band',
  'PR Firm',
  'Party Supplies',
  'Photographer',
  'Photographic Services & Equipment',
  'Public Relations',
  'Publisher',
  'Screen Printing & Embroidery',
  'Sports Promoter',
  'Stylist',
  'Talent Management',
  'Trophies & Engraving',
  'UX Design',
  'Video Services',
  'Web Design',
  'Wedding Hall',
  'Wedding Planning',
];
// Food and Restaurant
const FOOD_RESTAURANT = [
  'Afghani Restaurant',
  'African Restaurant',
  'American Restaurant',
  'Arepa Restaurant',
  'Argentinian Restaurant',
  'Asian Fusion Restaurant',
  'Asian Restaurant',
  'Australian Restaurant',
  'Austrian Restaurant',
  'BBQ Restaurant',
  'Bagel Shop',
  'Bakery',
  'Bar',
  'Bar & Grill',
  'Barbecue Restaurant',
  'Basque Restaurant',
  'Beach Bar',
  'Beer Bar',
  'Beer Garden',
  'Belgian Restaurant',
  'Bistro',
  'Bodega',
  'Brasserie',
  'Brazilian Restaurant',
  'Breakfast & Brunch Restaurant',
  'Brewery',
  'British Restaurant',
  'Bubble Tea Shop',
  'Buffet Restaurant',
  'Bulgarian Restaurant',
  'Burger Restaurant',
  'Burmese Restaurant',
  'Burrito Place',
  'Butcher Shop',
  'Cafe',
  'Cafeteria',
  'Cajun & Creole Restaurant',
  'Cambodian Restaurant',
  'Canadian Restaurant',
  'Candy Store',
  'Cantonese Restaurant',
  'Caribbean Restaurant',
  'Champagne Bar',
  'Chicken Restaurant',
  'Chicken Wings',
  'Chinese Restaurant',
  'Cocktail Bar',
  'Coffee Shop',
  'Comfort Food Restaurant',
  'Crêperie',
  'Cuban Restaurant',
  'Cupcake Shop',
  'Czech Restaurant',
  'Deli',
  'Dessert Place',
  'Dim Sum Restaurant',
  'Diner',
  'Dive Bar',
  'Doner Restaurant',
  'Donuts Shop',
  'Drive In Restaurant',
  'Dumpling Restaurant',
  'Dutch Restaurant',
  'Empanada Restaurant',
  'Ethiopian Restaurant',
  'Ethnic Grocery Store',
  'European Restaurant',
  'Falafel Restaurant',
  'Family Style Restaurant',
  'Farmers\' Market',
  'Fast Food Restaurant',
  'Filipino Restaurant',
  'Fine Dining Restaurant',
  'Fish & Chips Shop',
  'Fondue Restaurant',
  'Food Court',
  'Food Stand',
  'Food Truck',
  'French Restaurant',
  'Fried Chicken Restaurant',
  'Frozen Yogurt Shop',
  'Fruit & Vegetable Store',
  'Fujian Restaurant',
  'Gastropub',
  'German Restaurant',
  'Gluten-Free Restaurant',
  'Greek Restaurant',
  'Grocery Store',
  'Halal Restaurant',
  'Hawaiian Restaurant',
  'Health Food Restaurant',
  'Health Food Store',
  'Himalayan Restaurant',
  'Hong Kong Restaurant',
  'Hot Dog Joint',
  'Hot Dog Stand',
  'Hotel Bar',
  'Hotpot Restaurant',
  'Hunan Restaurant',
  'Hungarian Restaurant',
  'Ice Cream Parlor',
  'Indian Restaurant',
  'Indo Chinese Restaurant',
  'Indonesian Restaurant',
  'International Restaurant',
  'Internet Cafe',
  'Irish Pub',
  'Irish Restaurant',
  'Israeli Restaurant',
  'Italian Restaurant',
  'Japanese Curry Restaurant',
  'Japanese Restaurant',
  'Jazz Club',
  'Karaoke',
  'Kebab Restaurant',
  'Korean Restaurant',
  'Kosher Restaurant',
  'Kurdish Restaurant',
  'Late Night Restaurant',
  'Latin American Restaurant',
  'Lebanese Restaurant',
  'Liquor Store',
  'Live & Raw Food Restaurant',
  'Lounge',
  'Malay Restaurant',
  'Malaysian Restaurant',
  'Market',
  'Mediterranean Restaurant',
  'Mexican Restaurant',
  'Middle Eastern Restaurant',
  'Modern European Restaurant',
  'Molecular Gastronomy Restaurant',
  'Mongolian Restaurant',
  'Moroccan Restaurant',
  'Nepalese Restaurant',
  'New American Restaurant',
  'Night Club',
  'Noodle House',
  'Paella Restaurant',
  'Pakistani Restaurant',
  'Pastry Shop',
  'Peking Restaurant',
  'Persian/Iranian Restaurant',
  'Peruvian Restaurant',
  'Pet Cafe',
  'Pho Restaurant',
  'Pie Shop',
  'Pizza Place',
  'Polish Restaurant',
  'Polynesian Restaurant',
  'Portuguese Restaurant',
  'Poutine Place',
  'Pub',
  'Ramen Restaurant',
  'Restaurant',
  'Romanian Restaurant',
  'Russian Restaurant',
  'Sake Bar',
  'Salad Bar',
  'Salad Place',
  'Salvadoran Restaurant',
  'Sandwich Shop',
  'Satay Restaurant',
  'Scandinavian Restaurant',
  'Seafood Restaurant',
  'Shabu-Shabu Restaurant',
  'Shanghai Restaurant',
  'Singaporean Restaurant',
  'Smoothie & Juice Bar',
  'Soba Restaurant',
  'Soul Food Restaurant',
  'Soup Restaurant',
  'South American Restaurant',
  'Southern Restaurant',
  'Southwestern Restaurant',
  'Souvlaki Shop',
  'Spanish Restaurant',
  'Speakeasy',
  'Specialty Grocery Store',
  'Sports Bar',
  'Sri Lankan Restaurant',
  'Steakhouse',
  'Sushi Restaurant',
  'Swiss Restaurant',
  'Szechuan Restaurant',
  'Taco Place',
  'Taiwanese Restaurant',
  'Take Out Restaurant',
  'Tapas Bar & Restaurant',
  'Tea Room',
  'Tex-Mex Restaurant',
  'Thai Restaurant',
  'Theme Restaurant',
  'Tibetan Restaurant',
  'Tiki Bar',
  'Turkish Restaurant',
  'Udon Restaurant',
  'Ukranian Restaurant',
  'Vegetarian & Vegan Restaurant',
  'Venezuelan Restaurant',
  'Vietnamese Restaurant',
  'Wine Bar',
  'Winery/Vineyard',
];
// Health, Wellness, and Beauty
const HEALTH_WELLNESS_BEAUTY = [
  'AIDS Resources',
  'Acupuncture',
  'Addiction Resources',
  'Aesthetics',
  'Allergist',
  'Allergy Doctor',
  'Alternative & Holistic Health',
  'Alternative Healer',
  'Ambulance & Rescue',
  'Anesthesiologist',
  'Aromatherapy',
  'Audiologist',
  'Barber Shop',
  'Beauty Salon',
  'Blood Bank',
  'Cardiologist',
  'Chiropractor',
  'Clinic',
  'Cosmetic Surgeon',
  'Cosmetics & Beauty Supply',
  'Counseling & Mental Health',
  'Day Spa',
  'Dentist',
  'Dermatologist',
  'Disabled Persons Service',
  'Disaster Relief',
  'Doctor',
  'Doula',
  'Drug & Alcohol Rehab',
  'Ear Nose & Throat',
  'Emergency Medicine',
  'Endocrinologist',
  'Eye Doctor',
  'Family Doctor',
  'Family Medicine Practice',
  'Fertility',
  'First Aid Service',
  'Gastroenterologist',
  'Genealogist',
  'Geneticist',
  'Gerontologist',
  'Gym',
  'Hair Removal',
  'Hair Replacement',
  'Hair Salon',
  'Hairpieces & Extensions',
  'Halotherapy',
  'Health Agency',
  'Health Spa',
  'Healthcare Administrator',
  'Hearing Aid Provider',
  'Hepatologist',
  'Herbal Shop',
  'Home Health Care',
  'Hospice',
  'Hospital/Clinic',
  'Hospitalist',
  'Hydrotherapy',
  'Hypnosis/Hypnotherapy',
  'Immunodermatologist',
  'Internal Medicine',
  'Lactation Services',
  'Laser Eye Surgery/Lasik',
  'Laser Hair Removal',
  'Life Coach',
  'Makeup Artist',
  'Martial Arts',
  'Massage',
  'Maternity Clinic',
  'Medical Center',
  'Medical Lab',
  'Medical Research',
  'Medical Spa',
  'Medical Supplies',
  'Midwife',
  'Nail Salon',
  'Naturopathic/Holistic',
  'Nephrologist',
  'Neurologist',
  'Neuropathologist',
  'Neurotologist',
  'Nurse Practitioner',
  'Nursing',
  'Nursing Home',
  'Nutritionist',
  'OBGYN',
  'Obstetricians & Gynecologists',
  'Occupational Safety',
  'Occupational Therapist',
  'Oncologist',
  'Ophthalmologist',
  'Optometrist',
  'Orthopedist',
  'Osteopathic Physician',
  'Otolaryngologist',
  'Otologist',
  'Pain Management',
  'Pathologist',
  'Pediatrician',
  'Pediatrics',
  'Personal Coaching',
  'Personal Trainer',
  'Pharmacy/Drugstore',
  'Phlebologist',
  'Physical Fitness',
  'Physical Therapist',
  'Physician Assistant',
  'Placenta Encapsulation',
  'Plastic Surgery',
  'Podiatrist',
  'Pregnancy & Childbirth Service',
  'Prenatal/Perinatal Care',
  'Preventive Medicine',
  'Proctologist',
  'Prosthetic',
  'Prosthodontist',
  'Psychiatrist',
  'Psychologist',
  'Pulmonologist',
  'Radiologist',
  'Reflexology',
  'Rehabilitation Center',
  'Retirement & Assisted Living Facility',
  'Rheumatologist',
  'Safety & First Aid Service',
  'Sauna',
  'Skin Care',
  'Sleep Specialist',
  'Spa',
  'Speech Pathologist',
  'Speech Therapist',
  'Spine Surgeon',
  'Sports Medicine',
  'Surgeon',
  'Tanning Salon',
  'Tattoo & Piercing',
  'Tattoo Removal',
  'Teeth Whitening',
  'Toxicologist',
  'Traditional Chinese Medicine',
  'Undersea/Hyperbaric Medicine',
  'Urgent Care Center',
  'Urologist',
  'Vascular Medicine',
  'Weight Loss Center',
  'Wig Store',
  'Women\'s Health',
  'Yoga & Pilates',
];
// Law and Finance
const LAW_FINANCE = [
  'Accountant',
  'Appraiser',
  'Bail Bonds',
  'Bank',
  'Bankruptcy Lawyer',
  'Brokers & Franchising',
  'Cash Advance Service',
  'Civil Rights Law',
  'Collection Agency',
  'Commercial Bank',
  'Contract Law',
  'Corporate Lawyer',
  'Credit Counseling',
  'Criminal Lawyer',
  'Currency Exchange',
  'DUI Law',
  'Disability Law',
  'Divorce & Family Lawyer',
  'Employment Lawyer',
  'Entertainment Law',
  'Environmental Law',
  'Estate Planning',
  'Estate Planning Law',
  'Financial Advisor',
  'Financial Planning',
  'Financial Service',
  'General Litigation',
  'Governmental Law',
  'IP & Internet Law',
  'Immigration Law',
  'Insurance Agent',
  'Insurance Broker',
  'International Law',
  'Investing Service',
  'Juvenile Law',
  'Labor & Employment Law',
  'Law Practice',
  'Loans',
  'Malpractice Law',
  'Maritime Law',
  'Medical Law',
  'Military Law',
  'Mortgage Brokers',
  'Patent Trademark & Copyright Law',
  'Personal Injury Law',
  'Private Equity',
  'Private Investigator',
  'Property Law',
  'Real Estate Lawyer',
  'Social Security Law',
  'Sports Law',
  'Tax Law',
  'Tax Preparation',
  'Tenant and Eviction Law',
  'Traffic Ticketing Law',
  'Venture Capital',
  'Wills & Estate Lawyer',
  'Workers Compensation Law',
];
// Local Service
const LOCAL_SERVICE = [
  'ATVs & Golf Carts',
  'Adoption Service',
  'Agricultural Service',
  'Animal Shelter',
  'Archaeological Service',
  'Automation Service',
  'Automobile Leasing',
  'Automotive',
  'Automotive Body Shop',
  'Automotive Customizing',
  'Automotive Garage',
  'Automotive Glass Service',
  'Automotive Manufacturing',
  'Automotive Mechanic',
  'Automotive Parts & Accessories',
  'Automotive Repair',
  'Automotive Storage',
  'Automotive Wholesaler',
  'Aviation Fuel',
  'Awnings & Canopies',
  'Babysitter',
  'Bartending Service',
  'Biotechnology',
  'Blinds & Curtains',
  'Boat Dealership',
  'Boat Service',
  'Burial & Cremation Service',
  'Cabinets & Countertops',
  'Cable & Satellite Service',
  'Car Dealership',
  'Car Wash & Detailing',
  'Carpenter',
  'Carpet & Flooring Store',
  'Carpet Cleaner',
  'Caterer',
  'Cemetery',
  'Child Care',
  'Cleaning Service',
  'Commercial & Industrial Equipment',
  'Commercial Automotive',
  'Commercial Real Estate',
  'Commercial Real Estate Agent',
  'Commercial Real Estate Broker',
  'Concrete Contractor',
  'Contractor',
  'DJ',
  'DMV',
  'Damage Restoration Service',
  'Dating Service',
  'Deck & Patio',
  'Dog Training',
  'Dog Walker',
  'Dry Cleaner',
  'Electrician',
  'Elevator Service',
  'Emergency Roadside Service',
  'Emissions Inspection',
  'Engineering Service',
  'Entertainer',
  'Escrow Service',
  'Event Planner',
  'Event Venue',
  'Excavation & Wrecking',
  'Exotic Car Rental',
  'Fire Protection',
  'Fireplaces',
  'Food Consultant',
  'Forestry & Logging',
  'Funeral Service',
  'Garage Door Service',
  'Garden Center',
  'Gardener',
  'Gas & Chemical Service',
  'Gas Station',
  'Geologic Service',
  'Glass Service',
  'Hardware & Tools Service',
  'Hardware Store',
  'Heating, Ventilating & Air Conditioning',
  'Home Decor',
  'Home Improvement',
  'Home Inspection',
  'Home Security',
  'Home Theater Store',
  'Home Window Service',
  'Hospitality Service',
  'House Sitter',
  'Housewares',
  'Ice Machines',
  'Interior Designer',
  'Janitorial Service',
  'Kennel',
  'Kitchen Construction',
  'Kitchen/Cooking',
  'Landscaping',
  'Lighting Fixtures',
  'Locksmith',
  'Maid & Butler',
  'Manufacturing',
  'Marine Equipment',
  'Masonry',
  'Mattress Manufacturing',
  'Mattress Wholesale',
  'Mattresses & Bedding',
  'Metals',
  'Mobile Homes',
  'Motorcycle Repair',
  'Motorcycles',
  'Mover',
  'Musician/Band',
  'Nanny',
  'Oil Lube & Filter Service',
  'Painter',
  'Party Supplies',
  'Passport & Visa Service',
  'Patrol & Security',
  'Paving & Asphalt Service',
  'Personal Coaching',
  'Pest Control',
  'Pet Breeder',
  'Pet Cemetery',
  'Pet Groomer',
  'Pet Service',
  'Pet Sitter',
  'Pet Store',
  'Petroleum Service',
  'Plastics',
  'Plumber',
  'Portable Building Service',
  'Portable Toilet Rentals',
  'Private Investigator',
  'Property Management',
  'Psychic',
  'RV Dealership',
  'RV Repair',
  'Race Cars',
  'Railroad',
  'Real Estate',
  'Real Estate Agent',
  'Real Estate Appraiser',
  'Real Estate Developer',
  'Real Estate Investment',
  'Real Estate Service',
  'Real Estate Title & Development',
  'Recreational Vehicle Dealership',
  'Recycling & Waste Management',
  'Refrigeration',
  'Repair Service',
  'Residential Real Estate Agent',
  'Residential Real Estate Broker',
  'Robotics',
  'Roofer',
  'Safety & First Aid Service',
  'Security',
  'Septic Tank Service',
  'Sewer Service',
  'Sewing & Seamstress',
  'Shopping Service',
  'Snowmobiles',
  'Solar Energy Service',
  'Storage',
  'Storage Service',
  'Surveyor',
  'Swimming Pool Maintenance',
  'Taxidermist',
  'Textiles',
  'Tire Dealer & Repair Shop',
  'Tools Service',
  'Towing Service',
  'Traffic School',
  'Trailer Rental',
  'Truck Rental',
  'Truck Towing',
  'Tutoring',
  'Upholstery Service',
  'Vending Machine Service',
  'Veterinarian',
  'Wallpaper',
  'Warehouse',
  'Water Filtration & Treatment',
  'Wedding Hall',
  'Wedding Planning',
  'Well Water Drilling Service',
  'Window Service & Repair',
];
// Personal Website
const PERSONAL_WEBSITE = [
  'Accountant',
  'Architect',
  'Business Consultant',
  'Commercial Real Estate Agent',
  'Commercial Real Estate Broker',
  'Digital Design',
  'Dog Training',
  'Entrepreneur',
  'Fashion Designer',
  'Graphic Design',
  'Image Consultant',
  'Life Coach',
  'Maid & Butler',
  'Marketing Consultant',
  'Mobile Design',
  'Nanny',
  'Nutritionist',
  'Personal Coaching',
  'Personal Website',
  'Photographer',
  'Psychic',
  'Real Estate Agent',
  'Recruiter',
  'Residential Real Estate Agent',
  'Residential Real Estate Broker',
  'Sewing & Seamstress',
  'Stylist',
  'UX Design',
  'Web Design',
];
// Shopping and Retail
const SHOPPING_RETAIL = [
  'Accessories Store',
  'Antique Store',
  'Antiques & Vintage',
  'Arts & Crafts Supply Store',
  'Auction House',
  'Audiovisual Equipment',
  'Baby Store',
  'Big Box Retailer',
  'Bike Shop',
  'Book Store',
  'Bridal Shop',
  'Camera Store',
  'Children\'s Clothing Store',
  'Clothing Store',
  'Collectibles Store',
  'Comic Book Store',
  'Computer Store',
  'Computers & Electronics',
  'Convenience Store',
  'Costume Shop',
  'Cultural Gifts Store',
  'DVD & Video Store',
  'Department Store',
  'Discount Store',
  'Dry Cleaner',
  'Electronics Store',
  'Eyewear',
  'Fireworks Retailer',
  'Flea Market',
  'Florist',
  'Formal Wear',
  'Furniture Repair',
  'Furniture Store',
  'Gift Shop',
  'Gun Store',
  'Jewelry Store',
  'Laundromat',
  'Lottery Retailer',
  'Luggage Service',
  'Men\'s Clothing Store',
  'Mobile Phone Shop',
  'Music Store',
  'Musical Instrument Store',
  'Office Supplies',
  'Outdoor Equipment Store',
  'Outlet Store',
  'Party Center',
  'Pawn Shop',
  'Rent to Own Store',
  'Restaurant Wholesale',
  'Seasonal Store',
  'Shoe Store',
  'Shopping District',
  'Shopping Mall',
  'Signs & Banner Service',
  'Sporting Goods Store',
  'Sportswear',
  'Swimwear',
  'Thrift or Consignment Store',
  'Tobacco Store',
  'Toy Store',
  'Video Game',
  'Vintage Store',
  'Wholesale & Supply Store',
  'Winery/Vineyard',
  'Women\'s Clothing Store ',
];
// Technology and Startups
const TECH_STARTUPS = [
  'App Development',
  'Consumer Startup',
  'Copywriting Service',
  'Digital Design',
  'Email Marketing',
  'Enterprise Startup',
  'Graphic Design',
  'IP & Internet Law',
  'IT Services',
  'Mobile Design',
  'Mobile Development',
  'Mobile Startup',
  'Patent Trademark & Copyright Law',
  'Software Consulting',
  'Software Development',
  'Startup',
  'Technology Consultant',
  'Telemarketing Service',
  'UX Design',
  'Venture Capital',
  'Web Design',
];
// Travel and Transportation
const TRAVEL_TRANSPORTATION = [
  'Airline',
  'Airline Industry Service',
  'Airport',
  'Airport Lounge',
  'Airport Shuttle',
  'Airport Terminal',
  'Balloonport',
  'Beach',
  'Beach Resort',
  'Bed and Breakfast',
  'Bike Rental & Bike Share',
  'Boat Rental',
  'Bridge',
  'Bus Station',
  'Cabin',
  'Camp',
  'Campground',
  'Car Rental',
  'Cargo & Freight Company',
  'Charter Buses',
  'City',
  'Consulate & Embassy',
  'Cottage',
  'County',
  'Cruise',
  'Cruise Excursions',
  'Eco Tours',
  'Farm',
  'Ferry & Boat',
  'Heliport',
  'Hostel',
  'Hotel',
  'Inn',
  'Island',
  'Limo Service',
  'Lodge',
  'Lodging',
  'Motel',
  'Parking',
  'Passport & Visa Service',
  'Port',
  'Private Plane Charter',
  'Private Transportation',
  'Public Transportation',
  'RV Park',
  'Rental Company',
  'Rental Shop',
  'Resort',
  'School Transportation',
  'Scooter Rental',
  'Seaplane Base',
  'Subway & Light Rail Station',
  'Taxi',
  'Tour Company',
  'Tour Guide',
  'Tourist Information',
  'Tours & Sightseeing',
  'Train Station',
  'Travel Agency',
  'Urban Farm',
  'Vacation Home Rental',
  'Wildlife Sanctuary',
];

const mapTypeWithCategory = (type: string, category: string) => ({
  value: type,
  category,
});

export type Category = {
  text: string;
  options: string[];
  iconSrc?: string;
  seoEmoji: string;
};

export const CATEGORIES: Category[] = [
  {
    text: 'Arts, Entertainment and Recreation',
    options: ARTS_ENTERTAINMENT_RECREATION,
    iconSrc: assetLink('landing-ui/icons/ArtsIcon.svg'),
    seoEmoji: '🎭',
  },
  {
    text: 'Business Service',
    options: BUSINESS_SERVICE,
    iconSrc: assetLink('landing-ui/icons/BusinessServicesIcon.svg'),
    seoEmoji: '💼',
  },
  {
    text: 'Community, Organization and Education',
    options: COMMUNITY_ORG_EDUCATION,
    seoEmoji: '🏢',
  },
  {
    text: 'Creative and Lifestyle Services',
    options: CREATIVE_LIFESTYLE_SERVICES,
    iconSrc: assetLink('landing-ui/icons/CreativeIcon.svg'),
    seoEmoji: '📝',
  },
  {
    text: 'Food and Restaurant',
    options: FOOD_RESTAURANT,
    iconSrc: assetLink('landing-ui/icons/FoodIcon.svg'),
    seoEmoji: '🍽',
  },
  {
    text: 'Health, Wellness, and Beauty',
    options: HEALTH_WELLNESS_BEAUTY,
    iconSrc: assetLink('landing-ui/icons/HealthIcon.svg'),
    seoEmoji: '🏥',
  },
  {
    text: 'Law and Finance',
    options: LAW_FINANCE,
    iconSrc: assetLink('landing-ui/icons/LawIcon.svg'),
    seoEmoji: '💼',
  },
  {
    text: 'Local Service',
    options: LOCAL_SERVICE,
    iconSrc: assetLink('landing-ui/icons/LocalServicesIcon.svg'),
    seoEmoji: '👉',
  },
  {
    text: 'Personal Website',
    options: PERSONAL_WEBSITE,
    iconSrc: assetLink('landing-ui/icons/PersonalWebsiteIcon.svg'),
    seoEmoji: '💻',
  },
  {
    text: 'Shopping and Retail',
    options: SHOPPING_RETAIL,
    seoEmoji: '🛒',
  },
  {
    text: 'Technology and Startups',
    options: TECH_STARTUPS,
    seoEmoji: '💻',
  },
  {
    text: 'Travel and Transportation',
    options: TRAVEL_TRANSPORTATION,
    seoEmoji: '🧳',
  },
];

export const VISIBLE_BUSINESS_TYPES: Option[] = [
  ...ARTS_ENTERTAINMENT_RECREATION.map((t) => mapTypeWithCategory(t, 'Arts, Entertainment and Recreation')),
  ...BUSINESS_SERVICE.map((t) => mapTypeWithCategory(t, 'Business Service')),
  ...COMMUNITY_ORG_EDUCATION.map((t) => mapTypeWithCategory(t, 'Community, Organization and Education')),
  ...CREATIVE_LIFESTYLE_SERVICES.map((t) => mapTypeWithCategory(t, 'Creative and Lifestyle Services')),
  ...FOOD_RESTAURANT.map((t) => mapTypeWithCategory(t, 'Food and Restaurant')),
  ...HEALTH_WELLNESS_BEAUTY.map((t) => mapTypeWithCategory(t, 'Health, Wellness, and Beauty')),
  ...LAW_FINANCE.map((t) => mapTypeWithCategory(t, 'Law and Finance')),
  ...LOCAL_SERVICE.map((t) => mapTypeWithCategory(t, 'Local Service')),
  ...PERSONAL_WEBSITE.map((t) => mapTypeWithCategory(t, 'Personal Website')),
  ...SHOPPING_RETAIL.map((t) => mapTypeWithCategory(t, 'Shopping and Retail')),
  ...TECH_STARTUPS.map((t) => mapTypeWithCategory(t, 'Technology and Startups')),
  ...TRAVEL_TRANSPORTATION.map((t) => mapTypeWithCategory(t, 'Travel and Transportation')),
];

export function getCategoryForBusinessType(businessType: string) {
  return CATEGORIES.find((c) => c.options.includes(businessType));
}
