import React from 'react';
import { createUseStyles } from 'react-jss';
import { spacing, pxToRem, breakpoint } from 'src/theme';
import SectionContainer from 'src/sections/shared/SectionContainer';
import Typography from 'src/components/core/Typography';
import BusinessSearchInput from 'src/components/BusinessSearch';

const useStyles = createUseStyles({
  container: {
    // Let the menu overlap with any adjacent section
    zIndex: 'unset',
    overflow: 'unset',
  },
  text: {
    [breakpoint.down('sm')]: {
      textAlign: 'left',
    },
  },
  underline: {
    marginBottom: spacing(10),
    [breakpoint.down('sm')]: {
      marginLeft: 0,
      marginBottom: spacing(8),
    },
  },
});

const BusinessSearchSection: React.FC = () => {
  const classes = useStyles();
  return (
    <SectionContainer className={classes.container}>
      <Typography
        variant="h2"
        color="blue"
        underline
        centered
        className={classes.text}
        underlineClassName={classes.underline}
        style={{
          maxWidth: pxToRem(800),
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        Explore the tools personalized for your business
      </Typography>
      <BusinessSearchInput showCategoryGrid />
    </SectionContainer>
  );
};

export default BusinessSearchSection;
