import React from 'react';
import { navigate } from 'gatsby';
import { createUseStyles } from 'react-jss';
import ClickAwayListener from 'react-click-away-listener';
import { SearchIcon } from '@heroicons/react/solid';
import { CATEGORIES, getCategoryForBusinessType } from 'src/businessTypes';
import Typography from 'src/components/core/Typography';
import Clickable from 'src/components/core/Clickable';
import {
  pxToRem, spacing, breakpoint, Theme,
} from 'src/theme';
import { getUrlFriendlyName } from 'src/utils';
import useBusinessSearch from './utils';

const inputColor = '#003960';
const boxShadow = '0px 4px 4px rgb(0 0 0 / 25%)';
const borderRadius = 20;

const useStyles = createUseStyles((theme: Theme) => ({
  menuAnchor: {
    position: 'relative',
    width: '100%',
    height: 0,
  },
  menu: {
    zIndex: 1,
    padding: spacing(8),
    margin: 0,
    position: 'relative',
    top: 0,
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    boxShadow,
    borderBottomRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
  },
  options: {
    maxHeight: `calc(min(50vh, ${pxToRem(600)}))`,
    overflow: 'auto',
    marginLeft: spacing(-4),
  },
  option: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(4),
    '&:hover': {
      background: 'rgba(27, 179, 182, 0.1)',
    },
    '&:focus-within': {
      background: 'rgba(27, 179, 182, 0.1)',
    },
    '&:focus-visible': {
      background: 'rgba(27, 179, 182, 0.1)',
      outline: 'none',
    },
  },
  inputContainer: {
    padding: spacing(5),
    background: '#F7F7F7',
    display: 'flex',
    alignItems: 'center',
    borderRadius,
  },
  inputIcon: {
    width: pxToRem(24),
    height: pxToRem(24),
    marginRight: spacing(2),
    color: inputColor,
  },
  input: {
    background: 'transparent',
    outline: 'none',
    color: inputColor,
    fontSize: pxToRem(20),
    width: '100%',
  },
  grid: {
    marginTop: spacing(7),
    display: 'grid',
    gridTemplateColumns: `min(50%, ${pxToRem(600)}) 1fr`,
    columnGap: spacing(10),
    rowGap: spacing(5),
    [breakpoint.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(0, 1fr))',
      marginTop: spacing(4),
      rowGap: spacing(4),
    },
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.BLUE,
    '&:hover': {
      color: theme.palette.primary.BLUE_HOVER,
    },
  },
  gridIcon: {
    width: pxToRem(40),
    marginRight: spacing(6),
  },
  breadcrumb: {
    display: 'inline-block',
    '&:hover': {
      color: theme.palette.secondary.GRAY,
    },
  },
}));

const gridData = CATEGORIES.filter((c) => Boolean(c.iconSrc));

interface Props {
  showCategoryGrid: boolean,
}

const BusinessSearchInput: React.FC<Props> = ({ showCategoryGrid = true }) => {
  const classes = useStyles();
  const {
    menuRef,
    inputRef,
    optionsRef,
    input,
    menuOpen,
    categorySelected,
    options,
    selectCategoryFromGrid,
    setInputFocused,
    setMenuFocused,
    setCategorySelected,
    setInput,
    setMenuOpen,
  } = useBusinessSearch();

  return (
    <>
      <div
        className={classes.inputContainer}
        style={menuOpen ? {
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow,
        } : undefined}
        onFocus={() => setInputFocused(true)}
      >
        <SearchIcon className={classes.inputIcon} />
        <input
          ref={inputRef}
          className={classes.input}
          placeholder="Search categories"
          value={input}
          onChange={(e) => {
            setInput(e.target.value);
            setMenuOpen(true);
          }}
          onBlur={() => setInputFocused(false)}
        />
      </div>
      <div className={classes.menuAnchor}>
        {menuOpen && (
          <ClickAwayListener onClickAway={() => {
            setMenuFocused(false);
          }}
          >
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
            <menu
              ref={menuRef}
              className={classes.menu}
              onFocus={() => setMenuFocused(true)}
              onClick={() => {
                setMenuFocused(true);
              }}
              onTouchEnd={() => {
                setMenuFocused(true);
              }}
            >
              {/* eslint-enable jsx-a11y/click-events-have-key-events */}
              {/* eslint-enable jsx-a11y/no-noninteractive-element-interactions */}
              <Typography variant="p3" color="textSecondary" style={{ marginBottom: spacing(2) }}>
                <Clickable
                  onClick={() => {
                    setInput('');
                    setCategorySelected(null);
                  }}
                  className={classes.breadcrumb}
                >
                  All
                </Clickable>
                {' '}
                &gt;
                {' '}
                {categorySelected || ''}
                {!categorySelected && input.length > 1 && 'Search results'}
              </Typography>
              {!options.length && (
              <Typography variant="p3" color="textSecondary" style={{ marginTop: spacing(2) }}>
                No results.
              </Typography>
              )}
              <div className={classes.options} ref={optionsRef}>
                {options.map(({ label, isCategory }) => (
                  <Clickable
                    onClick={() => {
                      if (isCategory) {
                        setCategorySelected(label);
                      } else {
                        const category = getCategoryForBusinessType(label);
                        if (category != null) {
                          navigate(`/website/${getUrlFriendlyName(category.text)}/${getUrlFriendlyName(label)}`);
                        } else {
                          navigate(`/website/other/${getUrlFriendlyName(label)}`);
                        }
                      }
                    }}
                    className={classes.option}
                    key={label}
                  >
                    <Typography variant="p3" color="blue">
                      {label}
                    </Typography>
                  </Clickable>
                ))}
              </div>
            </menu>
          </ClickAwayListener>
        )}
      </div>
      {showCategoryGrid && (
        <div className={classes.grid}>
          {gridData.map(({ iconSrc, text }) => (
            <Clickable
              key={text}
              className={classes.gridItem}
              onClick={() => selectCategoryFromGrid(text)}
            >
              <img src={iconSrc} className={classes.gridIcon} alt={`${text} Icon`} />
              <Typography variant="p1" color="inherit" style={{ fontWeight: 600 }}>
                {text}
              </Typography>
            </Clickable>
          ))}
        </div>
      )}
    </>
  );
};

export default BusinessSearchInput;
